<div class="text-center loader-block" *ngIf="loading">
  <div class="spinner-border text-primary m-1" role="status">
    <span class="sr-only"> {{ "LOCALIZE.LOADING" | translate }}</span>
  </div>
</div>
<div *ngIf="!loading" class="position-relative">
  <div class="row">
    <div class="country_select" ngbDropdown>
      <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle
        role="button" tabindex="0" aria-label="Select Language">
        <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16">
        <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
        <span><i class="fas fa-chevron-down" style="position: relative;left:10%;cursor:pointer"></i></span>
      </button>
      <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
        <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
          (click)="setLanguage(item.text, item.lang, item.flag)"
          [ngClass]="{'active': cookieValue === item.lang}">
          <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
            class="align-middle">{{item.text}}</span>
        </a>
      </div>
    </div>
  </div>
  <div class="status-container">
    <div [ngClass]="statusClass" class="status-icon"></div>
    <div class="status-message">
      <h4 [ngClass]="{ 'green-text': statusClass === 'approved', 'red-text': statusClass !== 'approved' }">
        {{ statusMessage }}
      </h4>
    </div>
  </div>
</div>
