import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CoacheeCustomValue, CustomFieldConfig } from '../../dtos/custom-field-config.dto';

@Injectable({
  providedIn: 'root'
})
export class CoacheeManagementService {

  constructor() { }

  buildFormWithCustomFields(form: FormGroup, customFieldsConfig: CustomFieldConfig[], coacheeCustomValues: CoacheeCustomValue[]): void {
    const valuesMap = new Map(coacheeCustomValues.map(item => [item.name, item.value]));

    customFieldsConfig.forEach(field => {
      let initialValue = valuesMap.get(field.name);
      let control: FormControl;

      switch (field.type) {
        case 'enum':
          control = new FormControl(initialValue || '', Validators.required);
          break;
        case 'boolean':
          let boolValue = initialValue != null ? initialValue : '';
          control = new FormControl(boolValue, Validators.required);
          break;
        case 'number':
          control = new FormControl(initialValue || '', [Validators.required, Validators.min(0)]);
          break;
        case 'string':
          control = new FormControl(initialValue || '', Validators.required);
          break;
      }
      form.addControl(field.name, control);
    });
  }
}
