<div>
  <div class="consent-header">
    <h4>Consent or declaration of consent under data protection law for the use of personal data by SPARRKS Service GmbH
    </h4>
  </div>

  <!--list div start-->
  <div class="consent-container">
    <ol class="terms-condition-block mb-3">
      <!--div 1 start-->
      <div>
        <li class="main-heading">
          <h4> Reason for data collection and type of data collected
          </h4>
        </li>
        <hr>
        <div>
          <h4 class="secondary-heading">1.1 Implementation of the coaching and registration for it
          </h4>
          <p>Your employer enables you to take part in coaching arranged by SPARRKS Service GmbH, Schönhauser Allee
            167c, 10435 Berlin, at its own expense. For this purpose, your employer has concluded a contract with
            SPAARKS Service GmbH for the provision of coaching.
          </p>
          <p> To register for a coaching session of interest to you, you will be given access to a booking portal of
            SPARRKS Service GmbH, in which you must provide the following data for the purpose of booking and
            allocation:
          </p>
          <ul class="mb-2" style="list-style-type:disc;">
            <li>First name, last name,</li>
            <li>e-mail address, </li>
            <li>Language,</li>
            <li>employer,</li>
            <li>Position,</li>
            <li>professional experience,</li>
            <li>management experience,</li>
            <li>concerns.</li>
          </ul>
          <p>This data is used by SPAARKS Service GmbH exclusively to carry out the registration for coaching and to
            carry out and invoice the coaching.</p>
          <p>On the booking portal, you can choose a specific coaching session and a specific coach or have suitable
            coaches suggested to you. These coaches are freelancers and carry out the coaching sessions on the basis of
            service contracts with SPARRKS Service GmbH. SPARRKS Service GmbH will then store which coaching sessions
            and which coaches you have registered with.</p>
          <p>If you have decided on a coach in the aforementioned portal or have coaches suggested to you, the coach or
            coaches will receive a booking request in which SPAARKS Service GmbH transmits the aforementioned personal
            data to the potential coach so that they have a basis for deciding whether they can take on the coaching.
          </p>
          <p>If a coach rejects the booking request, the coach is obliged to delete this personal data immediately.</p>
          <p> If he accepts the booking, he will store the aforementioned data for the purpose of carrying out the
            coaching for the duration of the coaching. He may not use your data for any other purpose than to fulfill
            the specific coaching order; any further processing is prohibited by SPARRKS Service GmbH. The coaches have
            also been obliged by SPARRKS Service GmbH to delete all personal data relating to the coachees after the
            coaching has ended.</p>
          <p>The coaching itself is conducted via Microsoft Teams and is not recorded. Neither SPAARKS Service GmbH nor
            your employer have the possibility to access this in any way. The respective coach may, insofar as this is
            necessary to carry out the coaching, make notes during the coaching session about the course of the coaching
            and information provided by the coachee - whether on paper or offline on the PC used by the coach. This data
            will not be passed on to SPAARKS Service GmbH or to your employer. At the end of the coaching session, the
            coach is also obliged to delete or destroy these notes.</p>
          <p>Your personal data will be deleted in accordance with the applicable data protection regulations for three
            full calendar years plus a buffer period of three months after the end of the year in which the contract
            between your employer and Sparrks has been completely fulfilled.</p>
        </div>
      </div>
      <div>
        <h4 class="secondary-heading">1.2 Feedback survey </h4>
        <p>We also ask you to give SPARRKS Service GmbH your consent to the use of your data for the purpose of
          conducting a survey after the end of the coaching.</p>
        <p>If you give your consent to this, some of your data will also be stored after the end of the coaching and the
          billing to your employer for the purpose of conducting a feedback survey.</p>
        <p>In this case, you will receive a questionnaire by e-mail after the end of the coaching, which relates to the
          quality of the coaching and in particular whether the coaching met your expectations and whether you were
          satisfied with the coach and the content of the coaching. This questionnaire serves the sole purpose of
          internal quality assurance and to make improvements if necessary.</p>
        <p>In order to carry out this survey, the following data will be stored beyond the end of the coaching, provided
          you have given your consent:</p>
        <ul class="mb-2" style="list-style-type:disc;">
          <li>First name, last name,</li>
          <li>e-mail address, </li>
          <li>Language,</li>
          <li>employer,</li>
          <li>Position,</li>
          <li>professional experience,</li>
          <li>management experience,</li>
          <li>concerns,</li>
          <li>Coaching conducted and coach conducting the coaching.</li>
        </ul>
        <p>
          Your personal data will be deleted in accordance with the applicable data protection regulations for three
          full calendar years plus a buffer period of three months after the end of the year in which the contract
          between your employer and Sparrks has been completely fulfilled.
        </p>
      </div>
      <!--div 1 end-->
      <!--div 2 start-->
      <div>
        <li class="main-heading">
          <h4>Data processing is hosted by SPARRKS Service GmbH on the following servers:</h4>
        </li>
        <hr>
        <p>
          Data processing is hosted by SPARRKS Service GmbH on the following servers:
        </p>
        <ul class="mb-3" style="list-style-type:disc;">
          <li>Google data center, Frankfurt</li>
        </ul>
      </div>
      <!--div 2 end-->
      <!--div 3 start-->
      <div>
        <li class="main-heading">
          <h4>Rights of the data subject: Information, correction, deletion and blocking, revocation</h4>
        </li>
        <hr>
        <div>
          <p>As a data subject affected by data processing, you have the following rights: </p>
          <ul class="mb-3" style="list-style-type:disc;">
            <li>Information about your data stored by us and its processing (Art. 15 GDPR)</li>
            <li>Correction of incorrect personal data (Art. 16 GDPR)</li>
            <li>Erasure of your data stored by us (Art. 17 GDPR)</li>
            <li>Restriction of data processing if we are not yet allowed to delete your data due to legal obligations
              (Art. 18 GDPR)</li>
            <li>Data portability if you have consented to the data processing or have concluded a contract with us (Art.
              20 GDPR)</li>
            <li>You also have the right to revoke or amend the consent or consents you have given at any time for the
              future without giving reasons (Art. 21 GDPR).</li>
            <li>You can lodge a complaint with a supervisory authority at any time, e.g. with the competent supervisory
              authority in the federal state of your place of residence or with the authority responsible for us as the
              controller.</li>
          </ul>
        </div>

      </div>
      <!--div 3 end-->
      <!--div 4 start-->
      <div>
        <li class="main-heading">
          <h4>Controller within the meaning of the GDPR and contact person for data processing</h4>
        </li>
        <hr>
        <div>
          <p> SPARRKS Service GmbH is responsible for data processing. Dr. Nicolas Stephan Co-Founder & Managing
            Director SPARRKS Service GmbH is responsible for this. You can reach him at +49 (0)30 52014961 or by e-mail
            at <a href="mailto:nicolas.stephan@sparrks.de">nicolas.stephan@sparrks.de</a>.</p>
          <p> An external data protection officer has also been appointed at SPARRKS Service GmbH: Prof. Dr. Reinhold
            Mauer, who can be contacted at <a href="mailto:datenschutz@sparrks.de"> datenschutz@sparrks.de</a>.</p>
        </div>

      </div>
      <!--div 4 end-->
      <!--div 5 start-->
      <div>
        <li class="main-heading">
          <h4>Consequences of not signing</h4>
        </li>
        <hr>
        <p>You have the right not to consent to this data processing by SPARRKS Service GmbH.</p>
        <p>However, since the implementation of the coaching is dependent on the collection and processing of the
          aforementioned data, not ticking the relevant consent box and not signing it would preclude the use of the
          coaching.</p>
        <p>If you do not check the option that your data may be used for the purpose of a survey for quality assurance
          after the coaching has been carried out, this will have no consequences.</p>
      </div>
      <!--div 5 end-->
      <!--div 6 start-->
      <div class="pb-5 pt-3">
        <div class="main-heading">
          <h4 class="text-center">Consent</h4>
        </div>
        <p>I declare my consent that the aforementioned personal data about me may be stored by SPAARKS Service GmbH for
          the purpose of conducting the coaching. I declare that I give this consent voluntarily. I am aware that I can
          revoke my consent at any time with effect for the future. In the event of revocation, the relevant data
          protection provisions shall apply.
        </p>
        <p> I hereby also give my consent for the aforementioned personal data about me to be stored beyond the
          completion of the coaching in order to participate in a survey on the quality of the coaching, its content and
          the coach after the coaching has been completed.</p>
        <p> I hereby confirm that I consent to the collection and processing of my data by SPAARKS Service GmbH as
          indicated above and that I have been informed of my rights.</p>
      </div>
    </ol>
  </div>
  <!--list div end-->
</div>
