import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coach-data-consent',
  templateUrl: './coach-data-consent.component.html',
  styleUrls: ['./coach-data-consent.component.scss']
})
export class CoachDataConsentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
