import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coach-data-consent-de',
  templateUrl: './coach-data-consent-de.component.html',
  styleUrls: ['./coach-data-consent-de.component.scss']
})
export class CoachDataConsentDeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
