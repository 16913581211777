import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';

import { Page404Component } from './extrapages/page404/page404.component';
import { CoachAuthGuard } from './core/guards/coach-auth.guard';
import { TermsAndConditionComponent } from './shared/ui/terms-and-condition/terms-and-condition.component';
import { TermsAndConditionGermanComponent } from './shared/ui/terms-and-condition-german/terms-and-condition-german.component';
import { CoachDataConsentComponent } from './shared/ui/coach-data-consent/coach-data-consent.component';
import { DataProtectionConsentComponent } from './shared/ui/data-protection-consent/data-protection-consent.component';
import { DataProtectionConsentDeComponent } from './shared/ui/data-protection-consent-de/data-protection-consent-de.component';
import { CoachDataConsentDeComponent } from './shared/ui/coach-data-consent-de/coach-data-consent-de.component';
import { ApprovalHandlerComponent } from './extrapages/approval-handler/approval-handler.component';

const routes: Routes = [
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  // tslint:disable-next-line: max-line-length
  { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  { path: '', component: LayoutComponent, loadChildren: () => import('./pages-coach/pages-coach.module').then(m => m.PagesCoachModule), canActivate: [CoachAuthGuard] },
  { path:'terms-and-conditions', component: TermsAndConditionComponent},
  { path:'terms-and-conditions-de', component: TermsAndConditionGermanComponent},
  { path:'data-protection-consent', component: DataProtectionConsentComponent},
  { path:'data-protection-consent-de', component: DataProtectionConsentDeComponent},
  { path:'coach-data-consent', component: CoachDataConsentComponent},
  { path:'coach-data-consent-de', component: CoachDataConsentDeComponent},
  { path: 'approvals/coaching', component: ApprovalHandlerComponent },

  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
