import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CoachHttpService } from "./coach-http.service";



@Injectable({ providedIn: 'root' })

export class CoachService{
    constructor(private _coachHttpService:CoachHttpService){}

    //login service
      coachLogin(login_info) {

        return this._coachHttpService.postCoachLogin(login_info)
          .pipe(map(
            res => res
          ));
      }

       //coach sendPasswordToEmail
       sendPasswordToEmail(email_info) {
        return this._coachHttpService.sendPasswordToEmail(email_info)
          .pipe(map(
            res => res
          ));
      }

      //verify reset password token
      verifyPasswordResetToken(token_info) {
        return this._coachHttpService.verifyPasswordResetToken(token_info)
          .pipe(map(
            res => res
          ));
      }

       //verify reset password token
       verifyPasswordSetToken(token_info) {
        return this._coachHttpService.verifyPasswordSetToken(token_info)
          .pipe(map(
            res => res
          ));
      }

      //verify Time slot confirmation token
      verifyTimeSlotConfirmToken(token_info) {
        return this._coachHttpService.verifyTimeSlotConfirmToken(token_info)
          .pipe(map(
            res => res
          ));
      }

       //verify Time slot confirmation token
       verifyReschedulingTimeSlotConfirmToken(token_info) {
        return this._coachHttpService.verifyReschedulingTimeSlotConfirmToken(token_info)
          .pipe(map(
            res => res
          ));
      }

      //reset the password
      resetPassword(password_details) {
        return this._coachHttpService.resetPassword(password_details)
          .pipe(map(
            res => res
          ));
      }

       //set the password
       setPassword(password_details) {
        return this._coachHttpService.setPassword(password_details)
          .pipe(map(
            res => res
          ));
      }

      //confirmed time slot
      confirmedTimeSlot(timeslot_details) {
        return this._coachHttpService.confirmedTimeSlot(timeslot_details)
          .pipe(map(
            res => res
          ));
      }

      //confim rescheduling time
      confirmedReschedulingTimeSlot(timeslot_details) {
        return this._coachHttpService.confirmedReschedulingTimeSlot(timeslot_details)
          .pipe(map(
            res => res
          ));
      }

        getCoachJourneys(coachDetails) {
          return this._coachHttpService.getCoachJourneys(coachDetails)
            .pipe(map(
              res => res
            ));
        }

        //get coach journey file list to display
        fetchCoachJourneyFilesList(journey_id) {
          return this._coachHttpService.fetchCoachJourneyFilesList(journey_id)
            .pipe(map(
              res => res
            ));
        }

    //post coach journey file document
    coachUploadJourneyFiles(coach_journey_details) {
      return this._coachHttpService.coachUploadJourneyFiles(coach_journey_details)
        .pipe(map(
          res => res
        ));
    }

     //fetch coachee details
     fetchCoachProfileDetails(coach_id) {
      return this._coachHttpService.fetchCoachProfileDetails(coach_id)
        .pipe(map(
          res => res
        ));
    }

    // coachee password update
    coachPasswordUpdate(profile_details) {
      return this._coachHttpService.coachPasswordUpdate(profile_details)
        .pipe(map(
          res => res
        ));
    }

     // book subsequent session
     bookNextSession(journeyId, session_info) {
      return this._coachHttpService.bookNextSession(journeyId, session_info)
        .pipe(map(
          res => res
        ));
    }

    //end Journey
    endJourney(session_id) {
      return this._coachHttpService.endJourney(session_id)
        .pipe(map(
          res => res
        ));
    }

    coachJoinSession(journeyId, sessionId) {
      return this._coachHttpService.coachJoinSession(journeyId, sessionId)
        .pipe(map(
          res => res
        ));
    }

  //fetch preparation question and answers added by coachee
  fetchPrepQuestAndAnswers(session_id) {
    return this._coachHttpService.fetchPrepQuestAndAnswers(session_id)
      .pipe(map(
        res => res
      ));
  }

  //fetch session ended details
  fetchSessionEndedDetails(journey_id) {
    return this._coachHttpService.fetchSessionEndedDetails(journey_id)
      .pipe(map(
        res => res
      ));
  }

  //get qualitrics questions
  getCoachQualitricsQuestions(coach_id_and_language) {
    return this._coachHttpService.getCoachQualitricsQuestions(coach_id_and_language)
      .pipe(map(
        res => res
      ));
  }

  //store qualitrics answers and questions of coach
  storeCoachQualitricsQuestionsAnswers(question_answers) {
    return this._coachHttpService.storeCoachQualitricsQuestionsAnswers(question_answers)
      .pipe(map(
        res => res
      ));
  }

    //rescheduling session
    reschedulingSession(rescheduling_info) {
      return this._coachHttpService.reschedulingSession(rescheduling_info)
        .pipe(map(
          res => res
        ));
    }

     //get coachee action items
     getActionItemForCoachee(session_id) {
      return this._coachHttpService.getActionItemForCoachee(session_id)
        .pipe(map(
          res => res
        ));
    }
    //get coachee action items
    getKeyTakeawaysForCoachee(journey_info) {
      return this._coachHttpService.getKeyTakeawaysForCoachee(journey_info)
        .pipe(map(
          res => res
        ));
    }

    //save coaching history action items
    saveActionItemsEditedInCoachingHistory(journey_details) {
      return this._coachHttpService.saveActionItemsEditedInCoachingHistory(journey_details)
        .pipe(map(
          res => res
        ));
    }
    //save coaching history action items
    saveKeytakewaysEditedInCoachingHistory(journey_details) {
      return this._coachHttpService.saveKeytakewaysEditedInCoachingHistory(journey_details)
        .pipe(map(
          res => res
        ));
    }

    saveNotes(notes) {
      return this._coachHttpService.saveNotes(notes)
        .pipe(map(
          res => res
        ));
    }

    deleteFile(fileid) {
      return this._coachHttpService.Delete_coach_file(fileid)
        .pipe(map(
          res => res
        ));
    }

    markDocumentAsSeen(journeyId, fileId) {
      return this._coachHttpService.markDocumentAsSeen(journeyId, fileId)
        .pipe(map(
          res => res
        ));
    }

    getAnalyticsConfiguration() {
      return this._coachHttpService.getAnalyticsConfiguration()
        .pipe(map(
          res => res
        ));
    }

}
