<div class="top-bar">
  <div class="row d-flex align-items-center">
    <div class="col-12 col-md-6 col-lg-6 col-xl-7 d-flex align-items-center gap-3">
      <div class="menuToggle">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h1 class="responsive-top-heading">{{topbar_info}}</h1>
    </div>
    <div
      class="col-12 col-md-6 col-lg-6 col-xl-5 my-3 my-lg-0 d-flex align-items-center justify-content-end profile-block">

      <div class="dropdown d-flex justify-content-start col-6 col-md-6" ngbDropdown
        *ngIf="isCoachee == 'true' && isCoach == 'false'">
        <a [disableTooltip]="coachee_able_to_book" href="javascript:;" data-toggle="tooltip"
          ngbTooltip="{{'LOCALIZE.BOOKINGBTNTOOLTIP' | translate}}" tooltipClass="info-tooltip" tabindex="-1">
          <button id="topBarBookSessionBtn" class="btn submit-btn" data-bs-placement="top"
            (click)="openSessionBookingModal()" [disabled]="!coachee_able_to_book" role="button"
            tabindex="{{ coachee_able_to_book ? '0' : '-1' }}" data-bs-toggle="modal"
            data-bs-target="#session_booking_topbar" [attr.aria-label]="'LOCALIZE.BOOKCOACHING' | translate"
            [attr.aria-disabled]="!coachee_able_to_book">{{'LOCALIZE.BOOKCOACHING' | translate}}</button>
        </a>
      </div>

      <section class="col-6 col-md-6 d-flex align-items-center">
        <div class="dropdown d-flex justify-content-center align-items-center" ngbDropdown>
          <button role="button" type="button" class="btn header-item" ngbDropdownToggle
            id="page-header-user-details-dropdown" [attr.aria-label]="coacheeName" tabindex="0">
            <div class="profile-photo my-auto" style="cursor:pointer" *ngIf="isCoachee == 'true' && isCoach == 'false'"
              data-bs-toggle="modal" data-bs-target="#coacheeProfileModal">
              <div class="coachee-profile-name outlined-element d-flex align-items-center">
                {{coacheeName}}
              </div>
            </div>
            <div class="profile-photo" style="cursor:pointer" *ngIf="isCoachee == 'false' && isCoach == 'true'"
              (click)="goToCoachProfilePage()">
              <img
                [src]="coach_userDetails.profile_image_path? coach_userDetails.profile_image_path: 'assets/images/sparrks-logos/default_avatar.png'"
                alt="profile image'" data-holder-rendered="true">
            </div>
          </button>
        </div>
        <div class="dropdown d-flex justify-content-center align-items-center" ngbDropdown>
          <button role="button" type="button" class="header-item" style="background: transparent;"
            id="page-header-user-dropdown" ngbDropdownToggle aria-label="Select Language" tabindex="0">
            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16">
            <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
            <span><i class="fas fa-chevron-down" style="position: relative;left:10%;cursor:pointer"></i></span>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
              <img src="{{item.flag}}" alt="user image" class="me-1" height="12"> <span
                class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </div>
        <div class="dropdown d-flex justify-content-center align-items-center">
          <button role="button" type="button" class="header-item noti-icon right-bar-toggle"
            style="background: transparent;" [attr.aria-label]="'LOCALIZE.LOGOUT' | translate" tabindex="0">
            <i class="bx bx-log-out font-size-20 align-middle me-1 text-danger logout-icon"
              ngbTooltip="{{'LOCALIZE.LOGOUT' | translate}}" (click)="logout()"></i>
          </button>
        </div>
      </section>
    </div>

  </div>
</div>

<!-- Large Modal -->
<ng-template #largeDataModal let-modal>
  <div class="modal-header">
    <button role="button" type="button" class="btn-close" aria-hidden="true" aria-label="Close"
      (click)="modal.dismiss('Cross click')" tabindex="0"></button>
  </div>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <button role="button" type="button" class="btn btn primary" tabindex="0" (click)="logout()"
          [attr.aria-label]="'LOCALIZE.LOGOUT' | translate">{{'LOCALIZE.LOGOUT' | translate}}</button>
      </div>
    </div>
  </div>
</ng-template>


<!-- Modal -->
<div class="modal fade " id="coacheeProfileModal" tabindex="-1" aria-labelledby="modalTitleProfile" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalTitleProfile">{{'LOCALIZE.PROFIL' | translate}}</h5>
        <button role="button" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          tabindex="0"></button>
      </div>
      <div class="modal-body">
        <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav-tabs nav-tabs-custom nav-justified">
          <li [ngbNavItem]="1">
            <a ngbNavLink>
              <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
              <span class="d-none d-sm-block">{{'LOCALIZE.PERSONALINFO' | translate}}</span>
            </a>
            <ng-template ngbNavContent>
              <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>
              <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
              <span class="d-none d-sm-block">{{'LOCALIZE.PASSWORD' | translate}}</span>
            </a>
            <ng-template ngbNavContent>
              <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="customNav"></div>
      </div>
    </div>
  </div>
</div>
<!--modal end-->

<!-- Tab Content -->
<ng-template #TabContent>
  <form #submitForm="ngForm" (submit)="onSubmitProfileUpdateForm(submitForm)">
    <div class="row mb-3">
      <div class="col-md-6 form-group">
        <label class="label mb-2" for="firstName">{{'LOCALIZE.FIRSTNAME' | translate}}</label>
        <input type="text" class="form-control" id="firstName" name="first_name"
          [(ngModel)]="fetched_coachee_first_name" ngModel required
          [ngClass]="{'is-invalid': submitForm.submitted && submitForm.controls.first_name.errors}">
        <div class="invalid-feedback" *ngIf="submitForm.submitted && submitForm.controls.first_name.errors">
          <span>{{'LOCALIZE.THISFIELDREQUIRED' | translate}}</span>
        </div>
      </div>
      <div class="col-md-6 form-group mt-3 mt-md-0">
        <label class="label mb-2" for="lastName">{{'LOCALIZE.LASTNAME' | translate}}</label>
        <input type="text" class="form-control" name="last_name" id="lastName" [(ngModel)]="fetched_coachee_last_name"
          ngModel required [ngClass]="{'is-invalid': submitForm.submitted && submitForm.controls.last_name.errors}">
        <div class="invalid-feedback" *ngIf="submitForm.submitted && submitForm.controls.last_name.errors">
          <span>{{'LOCALIZE.THISFIELDREQUIRED' | translate}}</span>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-6 form-group">
        <label class="label mb-2" for="email">{{'LOCALIZE.BUSINESSEMAIL' | translate}}</label>
        <input readonly type="email" class="form-control " name="email" id="email" [(ngModel)]="fetched_email"
          [ngClass]="{'is-invalid': submitForm.submitted && submitForm.controls.email.errors}">
        <div class="invalid-feedback" *ngIf="submitForm.submitted && submitForm.controls.email.errors">
          <span>{{'LOCALIZE.THISFIELDREQUIRED' | translate}}</span>
        </div>
      </div>
      <div class="col-md-6 form-group mt-3 mt-md-0">
        <label class="label mb-2" for="language">{{'LOCALIZE.COACHINGLANGUAGE' | translate}}</label>
        <select name="language" id="language" class="form-control" [(ngModel)]="fetched_language" ngModel required
          [ngClass]="{'is-invalid': submitForm.submitted && submitForm.controls.language.errors}">
          <option value="" selected>{{'LOCALIZE.CHOOSEOPTION' | translate}}</option>
          <option [value]="'english'">{{'LOCALIZE.ENGLISH' | translate}}</option>
          <option [value]="'german'">{{'LOCALIZE.GERMAN' | translate}}</option>
        </select>
        <div class="invalid-feedback" *ngIf="submitForm.submitted && submitForm.controls.language.errors">
          <span>{{'LOCALIZE.THISFIELDREQUIRED' | translate}}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 form-group">
        <label class="label mb-2" for="fieldOfWork">{{'LOCALIZE.FIELDOFWORK' | translate}}</label>
        <input type="text" class="form-control" name="field_of_work" id="fieldOfWork" [(ngModel)]="fetched_position"
          ngModel required [ngClass]="{'is-invalid': submitForm.submitted && submitForm.controls.field_of_work.errors}">
        <div class="invalid-feedback" *ngIf="submitForm.submitted && submitForm.controls.field_of_work.errors">
          <span>{{'LOCALIZE.THISFIELDREQUIRED' | translate}}</span>
        </div>
      </div>
      <div class="col-md-6 form-group mt-3 mt-md-0">
        <label class="label mb-2" for="workExperience">{{'LOCALIZE.WORKEXPERIENCE' | translate}}</label>
        <input type="text" class="form-control" name="work_experience" id="workExperience"
          [(ngModel)]="fetched_work_experience" ngModel required
          [ngClass]="{'is-invalid': submitForm.submitted && submitForm.controls.work_experience.errors}">
        <div class="invalid-feedback" *ngIf="submitForm.submitted && submitForm.controls.work_experience.errors">
          <span>{{'LOCALIZE.THISFIELDREQUIRED' | translate}}</span>
        </div>
      </div>
    </div>


    <div class="d-grid gap-2 col-md-6 mx-auto mt-5 mb-3 mb-md-0">
      <button role="button" type="submit" class="btn primary-btn mx-auto update-personal-info" [disabled]="buttonLoader"
        [attr.aria-disabled]="buttonLoader" [attr.aria-label]="'LOCALIZE.UPDATECHANGES' | translate" tabindex="0">
        <div *ngIf="buttonLoader">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{'LOCALIZE.LOADING' | translate}}
        </div>
        <span *ngIf="!buttonLoader">
          {{'LOCALIZE.UPDATECHANGES' | translate}}
        </span>
      </button>
    </div>
  </form>
</ng-template>

<!-- Tab Content -->
<ng-template #TabContent1>
  <form #submitForm="ngForm" [formGroup]="passwordForm" (submit)="onPasswordUpdateForm(submitForm)">
    <div class="row mb-3">
      <div class="col-md-8 mx-auto">
        <label class="label mb-2">{{'LOCALIZE.OLDPASSWORD' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'LOCALIZE.OLDPASSWORD' | translate}}"
          formControlName="old_password" [ngClass]="{ 'is-invalid': submitted && f['old_password'].errors }">
        <div class="invalid-feedback" *ngIf="f['old_password'].hasError('required')">
          <span>{{'LOCALIZE.THISFIELDREQUIRED' | translate}}</span>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-8 mx-auto">
        <label class="label mb-2">{{'LOCALIZE.NEWPASWORD' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'LOCALIZE.NEWPASWORD' | translate}}"
          formControlName="new_password" [ngClass]="{ 'is-invalid': submitted && f['new_password'].errors }">
        <div class="invalid-feedback" *ngIf="submitted && f['new_password'].errors">
          <span *ngIf="f['new_password'].hasError('invalidPassword')">{{ 'LOGIN.PASSWORDREQUIREMENTS' | translate
            }}</span>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-8 mx-auto">
        <label class="label mb-2">{{'LOCALIZE.CONFIRMNEWPASSWORD' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'LOCALIZE.CONFIRMNEWPASSWORD' | translate}}"
          formControlName="confirm_password" [ngClass]="{ 'is-invalid': submitted && f['confirm_password'].errors }">
        <div class="invalid-feedback" *ngIf="submitted && f['confirm_password'].errors">
          <span *ngIf="f['confirm_password'].hasError('invalidPassword')">{{ 'LOGIN.PASSWORDREQUIREMENTS' | translate
            }}</span>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <span *ngIf="passwordNotMatched" style="color: red;text-align: center;">{{'LOCALIZE.PASSWORDDONOTMATCH' |
        translate}}</span>
      <span *ngIf="passwordSimilarToOldPassword"
        style="color: red;text-align: center;">{{'LOCALIZE.PLEASEENTERDIFFPASSWORDFROMOLDPASS' | translate}}</span>
    </div>

    <div class="d-grid gap-2 col-md-6 mx-auto mt-5">
      <button role="button" type="submit" class="btn primary-btn mx-auto update-personal-info" [disabled]="buttonLoader"
        [attr.aria-disabled]="buttonLoader" [attr.aria-label]="'LOCALIZE.CONFIRMANDUPDATENEWPASSWORD' | translate"
        tabindex="0">
        <div *ngIf="buttonLoader">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{'LOCALIZE.LOADING' | translate}}
        </div>
        <span *ngIf="!buttonLoader">
          {{'LOCALIZE.CONFIRMANDUPDATENEWPASSWORD' | translate}}
        </span>
      </button>
    </div>
  </form>
</ng-template>

<!-- Modal -->
<div class="modal fade " id="session_booking_topbar" tabindex="-1" aria-labelledby="modalTitleBookCoachingSession"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title" id="modalTitleBookCoachingSession">{{'LOCALIZE.BOOKCOACHING' | translate}}</div>
        <button role="button" type="button" class="btn-close hide-validation-msg" data-bs-dismiss="modal"
          aria-label="Close" tabindex="0"></button>
      </div>
      <div class=" sess_modal-body" style="padding-bottom: 30px !important;">
        <form [formGroup]="bookingForm" class="mx-5 resp-book-session" #bookSessionForm="ngForm"
          (submit)="onSubmitForm(bookSessionForm)">
          <div class="text-center title">{{ "LOCALIZE.TOPIC" | translate }}</div>
          <div class="book-coach"></div>

          <div class="row py-4">
            <div class="col-md-6 form-group">
              <label for="q-coach-option" class="label mb-2">{{ "LOCALIZE.DOYOUHAVECOACHALREADY" | translate }}<span
                  [attr.aria-label]="'Shared.MandatoryField' |translate">*</span></label>
              <select id="q-coach-option" class="form-select b_radius" formControlName="coach_option" required
                [ngClass]="{
                'is-invalid':
                bookSessionForm.submitted &&
                  bookingForm.get('coach_option').errors
              }" tabindex="0">
                <option value="" selected>
                  {{ "LOCALIZE.CHOOSEOPTION" | translate }}
                </option>
                <option value="yes">{{ "LOCALIZE.YES" | translate }}</option>
                <option value="no">{{ "LOCALIZE.NO" | translate }}</option>
              </select>
              <div class="invalid-feedback" *ngIf="
                bookSessionForm.submitted &&
                bookingForm.get('coach_option').errors
              ">
                <span>{{ "LOCALIZE.THISFIELDREQUIRED" | translate }}</span>
              </div>
            </div>
            <div class="col-md-6 form-group mt-3 mt-md-0" *ngIf="bookingForm.get('coach_option').value == 'yes'">
              <label for="q-coach-name" class="label mb-2 mb-md-1">{{ "LOCALIZE.COACHNAME" | translate }}<span
                  [attr.aria-label]="'Shared.MandatoryField' |translate">*</span></label>
              <input type="text" id="q-coach-name" class="form-control mt-0 mt-md-4 b_radius"
                formControlName="pref_coach_id" placeholder="{{ 'LOCALIZE.COACHNAME' | translate }}" [ngClass]="{
                'is-invalid':
                bookSessionForm.submitted &&
                  bookingForm.get('pref_coach_id').errors
              }" tabindex="0" />
              <div class="invalid-feedback" *ngIf="
                bookSessionForm.submitted &&
                bookingForm.get('pref_coach_id').errors
              ">
                <span>{{ "LOCALIZE.THISFIELDREQUIRED" | translate }}</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 form-group">
              <label for="q-topic" class="label mb-2">{{ "LOCALIZE.CHOOSETOPICOFFERED" | translate }}<span
                  [attr.aria-label]="'Shared.MandatoryField' |translate">*</span></label>
              <select id="q-topic" class="form-select b_radius" (change)="onChangeTopic($event)"
                formControlName="topic_id" [ngClass]="{
                'is-invalid':
                bookSessionForm.submitted && bookingForm.get('topic_id').errors
              }" required="" tabindex="0">
                <option value="" selected>
                  {{ "LOCALIZE.CHOOSEATOPIC" | translate }}
                </option>
                <option *ngFor="let iterator of topicsUsecasesList; let i = index" [value]="iterator.topic_id">
                  {{ iterator.topic_title }}
                </option>
              </select>
              <div class="invalid-feedback" *ngIf="
                bookSessionForm.submitted && bookingForm.get('topic_id').errors
              ">
                <span>{{ "LOCALIZE.THISFIELDREQUIRED" | translate }}</span>
              </div>
            </div>
            <div class="col-md-6 form-group mt-3 mt-md-0" [hidden]="disableUsecaseDropdown">
              <label for="q-usecase" class="label mb-2">{{ "LOCALIZE.USECASE" | translate }}<span
                  [attr.aria-label]="'Shared.MandatoryField' |translate">*</span></label>
              <select id="q-usecase" class="form-select b_radius" formControlName="usecase_id" [ngClass]="{
                'is-invalid':
                bookSessionForm.submitted &&
                  bookingForm.get('usecase_id').errors
              }" required tabindex="0">
                <option value="" selected>
                  {{ "LOCALIZE.CHOOSEAUSECASE" | translate }}
                </option>
                <option *ngFor="let usecase of usecasesList; let i = index" [value]="usecase.usecase_id">
                  {{ usecase.usecase_title }}
                </option>
              </select>
              <div class="invalid-feedback" *ngIf="
                bookSessionForm.submitted && bookingForm.get('usecase_id').errors
              ">
                <span>{{ "LOCALIZE.THISFIELDREQUIRED" | translate }}</span>
              </div>
            </div>
          </div>

          <div class="row py-3">
            <div class="form-group">
              <label for="exampleFormControlTextarea1" class="label mb-2">{{"LOCALIZE.DESCRIBEYOUR" | translate}}<span
                  [attr.aria-label]="'Shared.MandatoryField' |translate">*</span></label>
              <textarea class="form-control b_radius" id="exampleFormControlTextarea1" rows="3"
                formControlName="objectives" [ngClass]="{
                'is-invalid':
                bookSessionForm.submitted &&
                  bookingForm.get('objectives').errors
              }"></textarea>
              <div class="invalid-feedback" *ngIf="
                bookSessionForm.submitted && bookingForm.get('objectives').errors
              ">
                <span>{{ "LOCALIZE.THISFIELDREQUIRED" | translate }}</span>
              </div>
            </div>
          </div>

          <div class="row py-2">
            <div class="form-group">
              <div class="label mb-2">{{ "LOCALIZE.SUGGESTTIMESLOT" | translate }}<span aria-hidden="true">*</span>
              </div>
              <div class="row mb-3">
                <div class="time-slot justify-content-center">
                  <label for="q-timeslot-1" class="label mb-2 d-flex align-items-center">{{ "LOCALIZE.TIMESLOT" |
                    translate }} 1<span [attr.aria-label]="'Shared.MandatoryField' |translate">*</span>:</label>
                  <div class="col-lg-5 col-md-5 col-12">
                    <input id="q-timeslot-1" type="datetime-local" formControlName="first_date_slot" (change)="
                      onChangedDate(
                        $event,
                        'bookingFirstDatePicker',
                        bookSessionForm
                      )
                    " class="form-control date-input mb-2" [ngClass]="{
                      'is-invalid':
                      bookSessionForm.submitted &&
                        bookingForm.get('first_date_slot').errors
                    }" [min]="todayDateTime" />
                    <span class="invalid-feedback" *ngIf="
                      bookSessionForm.submitted &&
                      bookingForm.get('first_date_slot').errors
                    ">
                      {{ "LOCALIZE.THISFIELDREQUIRED" | translate }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="time-slot justify-content-center">
                  <label for="q-timeslot-2" class="label mb-2 d-flex align-items-center">{{ "LOCALIZE.TIMESLOT" |
                    translate }} 2<span [attr.aria-label]="'Shared.MandatoryField' |translate">*</span> :</label>
                  <div class="col-lg-5 col-md-5 col-12">
                    <input id="q-timeslot-2" type="datetime-local" formControlName="second_date_slot"
                      class="form-control date-input mb-2" (change)="
                      onChangedDate(
                        $event,
                        'bookingSecondDatePicker',
                        bookSessionForm
                      )
                    " [ngClass]="{
                      'is-invalid':
                      bookSessionForm.submitted &&
                        bookingForm.get('second_date_slot').errors
                    }" [min]="todayDateTime" />
                    <span class="invalid-feedback" *ngIf="
                      bookSessionForm.submitted &&
                      bookingForm.get('second_date_slot').errors
                    ">
                      {{ "LOCALIZE.THISFIELDREQUIRED" | translate }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="time-slot justify-content-center">
                  <label for="q-timeslot-3" class="label mb-2 d-flex align-items-center">{{ "LOCALIZE.TIMESLOT" |
                    translate }} 3<span [attr.aria-label]="'Shared.MandatoryField' |translate">*</span> :</label>
                  <div class="col-lg-5 col-md-5 col-12">
                    <input id="q-timeslot-3" type="datetime-local" formControlName="third_date_slot"
                      class="form-control date-input" (change)="
                      onChangedDate(
                        $event,
                        'bookingThirdDatePicker',
                        bookSessionForm
                      )
                    " [ngClass]="{
                      'is-invalid':
                      bookSessionForm.submitted &&
                        bookingForm.get('third_date_slot').errors
                    }" [min]="todayDateTime" />
                    <span class="invalid-feedback" *ngIf="
                      bookSessionForm.submitted &&
                      bookingForm.get('third_date_slot').errors
                    ">
                      {{ "LOCALIZE.THISFIELDREQUIRED" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section>
            <h5 class="text-center title">{{ "LOCALIZE.PERSONALINFO" | translate }}</h5>
            <div class="book-coach"></div>
            <div class="row py-2 pt-3">
              <div class="col-md-6 form-group">
                <label for="q-field-of-work" class="label mb-2">{{"LOCALIZE.FIELDOFWORK" | translate}}</label>
                <input id="q-field-of-work" type="text" class="form-control b_radius" formControlName="field_of_work"
                  [ngClass]="{
                  'is-invalid':
                  bookSessionForm.submitted &&
                    bookingForm.get('field_of_work').errors
                }" />
                <div class="invalid-feedback" *ngIf="
                  bookSessionForm.submitted &&
                  bookingForm.get('field_of_work').errors
                ">
                  <span>{{ "LOCALIZE.THISFIELDREQUIRED" | translate }}</span>
                </div>
              </div>
              <div class="col-md-6 form-group mt-3 mt-lg-0">
                <label for="q-work-experience" class="label mb-2">{{"LOCALIZE.WORKEXPERIENCE" | translate}}</label>
                <input id="q-work-experience" type="number" min="0" class="form-control b_radius"
                  formControlName="work_experience" [ngClass]="{'is-invalid':
              bookSessionForm.submitted &&
              bookingForm.get('work_experience').errors}" />
                <div class="invalid-feedback" *ngIf="
                  bookSessionForm.submitted &&
                  bookingForm.get('work_experience').errors
                ">
                  <span>{{ "LOCALIZE.THISFIELDREQUIRED" | translate }}</span>
                </div>
              </div>
            </div>

            <div class="row py-2">
              <div class="col-md-6 form-group order-1 order-md-1">
                <label for="q-language" class="label mb-2">{{"LOCALIZE.COACHINGLANGUAGE" | translate}}</label>
                <div class="align-items-end">
                  <select id="q-language" class="form-select b_radius" formControlName="language" [ngClass]="{
                    'is-invalid':
                    bookSessionForm.submitted &&
                      bookingForm.get('language').errors
                  }">
                    <option value="" selected>
                      {{ "LOCALIZE.CHOOSEOPTION" | translate }}
                    </option>
                    <ng-container *ngFor="let lang of languageList">
                      <option [value]="lang.language_id">
                        {{
                        language == "english" ? lang.language : lang.language_de
                        }}
                      </option>
                    </ng-container>
                  </select>
                  <div class="invalid-feedback d-block" *ngIf="
                    bookSessionForm.submitted && bookingForm.get('language').errors
                  ">
                    <span>{{ "LOCALIZE.THISFIELDREQUIRED" | translate }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 form-group mt-3 mt-lg-0 order-3 order-md-2">
                <label for="q-phone-country" class="label mb-2">{{ "LOCALIZE.PHONE" | translate
                  }}:{{"LOCALIZE.COUNTRYCODE" | translate}}<span
                    [attr.aria-label]="'Shared.MandatoryField' |translate">*</span></label>
                <div class="align-items-end">
                  <select id="q-phone-country" class="form-select b_radius" formControlName="country_code" [ngClass]="{
                    'is-invalid':
                    bookSessionForm.submitted &&
                      bookingForm.get('country_code').errors
                  }" tabindex="0">
                    <option value="" selected>
                      {{ "LOCALIZE.CHOOSEOPTION" | translate }}
                    </option>
                    <ng-container *ngFor="let country of countryList">
                      <option [value]="country.phonecode">
                        <span>{{ country.country_name }}</span>&nbsp; (<span>+{{ country.phonecode }}</span>)
                      </option>
                    </ng-container>
                  </select>
                  <div class="invalid-feedback d-block" *ngIf="
                    bookSessionForm.submitted &&
                    bookingForm.get('country_code').errors
                  ">
                    <span>{{ "LOCALIZE.THISFIELDREQUIRED" | translate }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 form-group mt-3 mt-lg-0 order-4 order-md-3">
                <label for="q-phone-number" class="label mb-2">{{ "LOCALIZE.PHONENUMBER" | translate }}<span
                    [attr.aria-label]="'Shared.MandatoryField' |translate">*</span></label>
                <input id="q-phone-number" type="number" min="0" class="form-control b_radius"
                  formControlName="phone_number" [ngClass]="{
                  'is-invalid':
                  bookSessionForm.submitted &&
                    bookingForm.get('phone_number').errors
                }" tabindex="0" />
                <div class="invalid-feedback" *ngIf="
                  bookSessionForm.submitted &&
                  bookingForm.get('phone_number').errors
                ">
                  <span>{{ "LOCALIZE.THISFIELDREQUIRED" | translate }}</span>
                </div>
              </div>

              <div class="col-md-6 form-group order-2 order-md-4" *ngIf="bookingForm.get('language').value == 3">
                <label for="q-other-language" class="label mb-2 mt-4">{{"LOCALIZE.OTHERLANGUAGE" | translate}}</label>
                <input id="q-other-language" name="other_language_pref" ngModel [(ngModel)]="other_language_pref"
                  type="text" class="form-control b_radius" placeholder="{{ 'LOCALIZE.OTHERLANGUAGE' | translate }}"
                  tabindex="0" />
                <div class="invalid-feedback">
                  <span>{{ "LOCALIZE.THISFIELDREQUIRED" | translate }}</span>
                </div>

              </div>

            </div>

          </section>

          <section *ngIf="customFieldsConfig && customFieldsConfig.length > 0">
            <h5 class="text-center title mt-3">{{ "LOCALIZE.ADDITIONALINFO" | translate }}</h5>
            <div class="book-coach"></div>
            <div class="row py-2">
              <div *ngFor="let field of customFieldsConfig" [ngSwitch]="field.type" class="col-md-6 form-group">
                <label for="q-custom-{{field.name}}" class="label mb-2 mt-2 label-custom">{{ field.title[browserLang]
                  }}</label>

                <input *ngSwitchCase="'string'" id="q-custom-{{field.name}}" type="text" [formControlName]="field.name"
                  class="form-control b_radius"
                  [ngClass]="{'is-invalid': bookSessionForm.submitted && bookingForm.get(field.name).errors}">

                <input *ngSwitchCase="'number'" id="q-custom-{{field.name}}" type="number"
                  [formControlName]="field.name" class="form-control b_radius"
                  [ngClass]="{'is-invalid': bookSessionForm.submitted && bookingForm.get(field.name).errors}">

                <select *ngSwitchCase="'boolean'" id="q-custom-{{field.name}}" class="form-select b_radius"
                  [formControlName]="field.name"
                  [ngClass]="{'is-invalid': bookSessionForm.submitted && bookingForm.get(field.name).errors}">
                  <option value="" [selected]="bookingForm.get(field.name).value === null" disabled>
                    {{ "LOCALIZE.CHOOSEOPTION" | translate }}
                  </option>
                  <option [value]="true" [selected]="bookingForm.get(field.name).value === true">
                    {{ "LOCALIZE.YES" | translate }}
                  </option>
                  <option [value]="false" [selected]="bookingForm.get(field.name).value === false">
                    {{ "LOCALIZE.NO" | translate }}
                  </option>
                </select>

                <select *ngSwitchCase="'enum'" id="q-custom-{{field.name}}" [formControlName]="field.name"
                  class="form-select b_radius"
                  [ngClass]="{'is-invalid': bookSessionForm.submitted && bookingForm.get(field.name).errors}">
                  <option value="" selected>
                    {{ "LOCALIZE.CHOOSEOPTION" | translate }}
                  </option>
                  <option *ngFor="let option of field.enum" [value]="option.key">
                    {{ option[browserLang] }}
                  </option>
                </select>

                <div class="invalid-feedback" *ngIf="bookSessionForm.submitted && bookingForm.get(field.name).errors">
                  {{ "LOCALIZE.THISFIELDREQUIRED" | translate }}
                </div>
              </div>
            </div>
          </section>

          <div class="d-grid gap-2 col-4 col-md-4 mx-auto mt-4">
            <button role="button" type="submit" class="btn submit-btn mt-3 submit-coaching" [disabled]="buttonLoader"
              [attr.aria-disabled]="buttonLoader" [attr.aria-label]="'LOCALIZE.SUBMIT' | translate" tabindex="0">
              <div *ngIf="buttonLoader">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </div>
              <span *ngIf="!buttonLoader">
                {{ "LOCALIZE.SUBMIT" | translate }}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
