import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { DataProtectionConsentComponent } from './data-protection-consent/data-protection-consent.component';
import { CoachDataConsentComponent } from './coach-data-consent/coach-data-consent.component';
import { DataProtectionConsentDeComponent } from './data-protection-consent-de/data-protection-consent-de.component';
import { CoachDataConsentDeComponent } from './coach-data-consent-de/coach-data-consent-de.component';

@NgModule({
  declarations: [
    DataProtectionConsentComponent,
    CoachDataConsentComponent,
    DataProtectionConsentDeComponent,
    CoachDataConsentDeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    RouterModule
  ],
  exports: []
})
export class UIModule { }
