<div>
  <div class="consent-header">
    <h4>Privacy Policy</h4>
    <h4>Sparrks Service GmbH</h4>
  </div>

  <!--list div start-->
  <div>
    <ol class="terms-condition-block">
      <!--div 1 start-->
      <div>
        <li class="main-heading mt-3">
          <h4>Name and contact details of the controller and the company data privacy officer</h4>
        </li>
        <hr>
        <p>This data privacy information applies to data processing by</p>
        <p>Data controller: SPARRKS Service GmbH ("sparrks"), Schönhauser Allee 167c, 10435 Berlin,
          Local Court Charlottenburg (Berlin),HRB 226047 B, represented by the managing directors Jakob Hansen and Dr.
          Nicolas Stephan</p>
        <p>The external data protection officer of sparrks is Prof. Dr. Reinhold Mauer, who can be contacted at <a
            href="mailto:datenschutz@sparrks.de"> datenschutz@sparrks.de</a>.</p>
      </div>
      <!--div 1 end-->
      <!--div 2 start-->
      <div>
        <li class="main-heading">
          <h4>Collection and storage of personal data and the nature and purpose of their use</h4>
        </li>
        <hr>
        <div>
          <h4 class="secondary-heading">a) When visiting the website
          </h4>
          <p>When you visit our website www.sparrks.io, the browser used on your device automatically sends information
            to the server of our website. This information is temporarily stored in a so-called log file. The following
            information is collected without any action on your part and stored until it is automatically deleted
          </p>
          <ul class="mb-2" style="list-style-type:disc;">
            <li>IP address of the requesting computer,</li>
            <li>Date and time of access,</li>
            <li>Name and URL of the accessed file,</li>
            <li>the website from which access was made (referrer URL)</li>
            <li>browser used and, if applicable, the operating system of your computer and the name of your access
              provider.
            </li>
          </ul>
          <p>The aforementioned data is processed by us for the following purposes
          </p>
          <ul class="mb-2" style="list-style-type:disc;">
            <li>Ensuring a smooth connection to the website,</li>
            <li>Ensuring a comfortable use of our website,</li>
            <li>evaluating system security and stability and for other administrative purposes.</li>
            <li>zu weiteren administrativen Zwecken.
            </li>
          </ul>
          <p>The legal basis for data processing is Art. 6 para. 1 sentence 1 lit. f GDPR. Our legitimate interest
            follows from the data collection purposes listed above.
          </p>
          <p>In addition, we use cookies and analysis services when you visit our website. You can find more detailed
            explanations in sections 4 and 5 of this privacy policy.</p>
        </div>
        <div>
          <h4 class="secondary-heading">b) When using our contact form
          </h4>
          <p>For questions of any kind, we offer you the opportunity to contact us via a form provided on the website.
            It is necessary to provide a valid e-mail address so that we know who sent the request and can answer it.
            Further information can be provided voluntarily.</p>
          <p>Data processing for the purpose of contacting us is carried out in accordance with Art. 6 para. 1 sentence
            1 lit. a GDPR on the basis of your voluntarily given consent.
          <p>The personal data collected by us for the use of the contact form will be automatically deleted after your
            request has been processed.</p>
        </div>
      </div>
      <!--div 2 end-->
      <!--div 3 start-->
      <div>
        <li class="main-heading">
          <h4>Transfer of data</h4>
        </li>
        <hr>

        <div>
          <p>Your personal data will not be transferred to third parties for purposes other than those listed below.
          </p>
          <p>We only pass on your personal data to third parties if:</p>
          <ul class="mb-3" style="list-style-type:disc;">
            <li>you have given your express consent to this in accordance with Art. 6 para. 1 sentence 1 lit. a GDPR,
            </li>
            <li>the disclosure pursuant to Art. 6 para. 1 sentence 1 lit. f GDPR is necessary for the assertion,
              exercise or defense of legal claims and there is no reason to assume that you have an overriding interest
              worthy of protection in not disclosing your data,</li>
            <li>in the event that there is a legal obligation for the disclosure pursuant to Art. 6 para. 1 sentence 1
              lit. c GDPR, and</li>
            <li>his is legally permissible and necessary for the processing of contractual relationships with you in
              accordance with Art. 6 para. 1 sentence 1 lit. b GDPR.
            </li>
          </ul>
        </div>
      </div>
      <!--div 3 end-->
      <!--div 4 start-->
      <div>
        <li class="main-heading">
          <h4>Cookies</h4>
        </li>

        <p>
          We use cookies on our website. These are small files that your browser automatically creates and that are
          stored on your end device (laptop, tablet, smartphone, etc.) when you visit our website. Cookies do not cause
          any damage to your end device and do not contain any viruses, Trojans or other malware.
        </p>
        <p>
          Information is stored in the cookie that results in each case in connection with the specific end device used.
          However, this does not mean that we obtain direct knowledge of your identity.
        </p>
        <p>On the one hand, the use of cookies serves to make the use of our website more convenient for you. For
          example, we use so-called session cookies to recognize that you have already visited individual pages of our
          website. These are automatically deleted after you leave our site.</p>
        <p>In addition, we also use temporary cookies to optimize user-friendliness, which are stored on your end device
          for a specified period of time. If you visit our site again to use our services, it is automatically
          recognized that you have already visited us and which entries and settings you have made so that you do not
          have to enter them again.</p>
        <p>On the other hand, we use cookies to statistically record the use of our website and to evaluate it for the
          purpose of optimizing our offer for you (see section 5). These cookies enable us to automatically recognize
          that you have already visited our website when you visit it again. These cookies are automatically deleted
          after a defined period of time.</p>
        <p>The data processed by cookies is required for the purposes mentioned to protect our legitimate interests and
          those of third parties in accordance with Art. 6 para. 1 sentence 1 lit. f GDPR.
        </p>
        <p>Most browsers accept cookies automatically. However, you can configure your browser so that no cookies are
          stored on your computer or a message always appears before a new cookie is created. However, completely
          deactivating cookies may mean that you cannot use all the functions of our website.</p>
      </div>

      <!--div 4 end-->
      <!--div 5 start-->
      <div>
        <li class="main-heading">
          <h4 class="mt-2">Analysis tools</h4>
        </li>
        <hr>
        <div>
          <h4 class="secondary-heading"> a) Tracking tools</h4>
          <p>
            The tracking measures listed below and used by us are carried out on the basis of Art. 6 para. 1 sentence 1
            lit. f GDPR. With the tracking measures used, we want to ensure a needs-based design and the continuous
            optimization of our website. On the other hand, we use the tracking measures to statistically record the use
            of our website and to evaluate it for the purpose of optimizing our offer for you. These interests are to be
            regarded as legitimate within the meaning of the aforementioned provision.
          </p>
          <p>The respective data processing purposes and data categories can be found in the corresponding tracking
            tools.</p>
          <h4 class="secondary-heading">Google Tag Manager</h4>
          <ul style="list-style-type:disc;">
            <li>What personal data is collected and to what extent is it processed? On our website, we use the service
              of Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland (hereinafter: Google Tag
              Manager). Google Tag Manager provides a technical platform for executing and controlling other web
              services and web tracking programs in a bundled manner by means of so-called "tags". In this context,
              Google Tag Manager stores cookies on your computer and, if web tracking tools are executed using Google
              Tag Manager, analyzes your surfing behavior (so-called "tracking"). This data sent by individual tags
              integrated into Google Tag Manager is merged, stored and processed by Google Tag Manager under a uniform
              user interface. All integrated "tags" are listed separately in this privacy policy. You can find more
              information on data protection for the tools integrated in Google Tag Manager in the relevant section of
              this privacy policy. When using our website with activated integration of Google Tag Manager tags, data
              such as your IP address and your user activities are transmitted to servers of Google Ireland Limited.
              With regard to the web services integrated via Google Tag Manager, the regulations in the respective
              section of this privacy policy apply. The tracking tools used in Google Tag Manager ensure that the IP
              address of Google Tag Manager is anonymized before transmission by means of IP anonymization of the source
              code. Google Tag Manager only enables the anonymized collection of IP addresses (so-called IP masking).
            </li>
            <li>Legal basis for the processing of personal data The legal basis for data processing is your consent in
              our information banner regarding the use of cookies and web tracking (consent through clear confirmatory
              action or behavior) in accordance with Art. 6 para. 1 lit. a GDPR.</li>
            <li>Purpose of data processing On our behalf, Google will use the information obtained via Google Tag
              Manager to evaluate your visit to this website, to compile reports on website activity and to provide us
              with other services relating to website activity and internet usage.</li>
            <li>Duration of storage Google will store the data relevant for the function of Google Tag Manager for as
              long as it is necessary to fulfill the booked web service. Data collection and storage is anonymized. If
              there is any personal reference, the data will be deleted immediately, provided that it is not subject to
              any statutory retention obligations. In any case, the deletion takes place after expiry of the retention
              obligation.</li>
            <li>Objection and deletion option You can prevent the collection and forwarding of personal data to Google
              (in particular your IP address) and the processing of this data by Google by deactivating the execution of
              script code in your browser, installing a script blocker in your browser or activating the "Do Not Track"
              setting in your browser. You can also prevent Google from collecting the data generated by the Google
              cookie and relating to your use of the website (including your IP address) and from processing this data
              by Google by clicking on the following link <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">http://tools.google.com/dlpage/gaoptout?hl=d</a> download and
              install the available browser plug-in. You can find Google's security and data protection principles at
              <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>.</li>
          </ul>
        </div>
        <br>
        <div>
          <h4 class="secondary-heading"> Google Analytics</h4>
          <ul class="mb-3" style="list-style-type:disc;">

            <li>Scope of the processing of personal data On our website, we use the web tracking service of Google
              Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland (hereinafter: Google Analytics). As part
              of web tracking, Google Analytics uses cookies that are stored on your computer and enable an analysis of
              the use of our website and your surfing behavior (so-called tracking). We carry out this analysis on the
              basis of the Google Analytics tracking service in order to constantly optimize our website and make it
              more accessible. When you use our website, data, in particular your IP address and your user activities,
              are transmitted to servers of Google Ireland Limited. We carry out this analysis on the basis of Google's
              tracking service in order to constantly optimize our website and make it more accessible. We also require
              web tracking for security reasons. Web tracking enables us to track whether third parties are attacking
              our website. The information from the web tracker enables us to take effective countermeasures and protect
              the personal data processed by us from these cyber attacks. By activating IP anonymization within the
              Google Analytics tracking code of this website, your IP address will be anonymized by Google Analytics
              before transmission. This website uses a Google Analytics tracking code that has been extended by the
              operator gat._anonymizeIp(); to enable only anonymized collection of IP addresses (so-called IP masking).
            </li>
            <li>Legal basis for the processing of personal data The legal basis for data processing is your consent in
              our information banner regarding the use of cookies and web tracking (consent through clear confirmatory
              action or behavior) in accordance with Art. 6 para. 1 lit. a GDPR.</li>
            <li>Purpose of data processing Google will use this information on our behalf to evaluate your visit to this
              website, to compile reports on website activity and to provide us with other services relating to website
              activity and internet usage. We also require web tracking for security reasons. Web tracking allows us to
              track whether third parties are attacking our website. The information from the web tracker enables us to
              take effective countermeasures and protect the personal data processed by us from these cyber attacks.
            </li>
            <li>Duration of storage Google will store the data relevant for the provision of web tracking for as long as
              it is necessary to fulfill the booked web service. Data collection and storage is anonymized. If there is
              any personal reference, the data will be deleted immediately, provided that it is not subject to any
              statutory retention obligations. In any case, the deletion takes place after expiry of the retention
              obligation.</li>
            <li>Objection and deletion options You can prevent the collection and forwarding of personal data to Google
              (in particular your IP address) and the processing of this data by Google by deactivating the execution of
              script code in your browser or activating the "Do Not Track" setting in your browser. You can also prevent
              Google from collecting the data generated by the Google cookie and relating to your use of the website
              (including your IP address) and from processing this data by Google by clicking on the following link
              (<a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">http://tools.google.com/dlpage/gaoptout?hl=de</a>) and install the available browser plug-in. You can find
              Google's security and data protection principles at <a href="https://policies.google.com/privacy?hl=de" target="_blank">https://policies.google.com/privacy?hl=de</a>.</li>
          </ul>
        </div>
        <div>
          <h4 class="secondary-heading"> HubSpot CRM</h4>
          <ul class="mb-3" style="list-style-type:disc;">
            <li>Wir nutzen das CRM-Tool von Hubspot Inc. 2 Canal Park, Cambridge, MA 02141, Vereinigte Staaten.</li>
            <li>HubSpot is an integrated software solution that can be used to cover various online marketing
              activities. This includes, for example, our website content management, email marketing, social media
              publishing & reporting, contact management, as well as the provision of any landing pages and contact
              forms.</li>
            <li>Our registration service allows our website visitors to find out more about our company. You can also
              download content and provide your contact information and other demographic information. This information
              and the content of our website is stored on the servers of our software partner HubSpot and can be used by
              us to contact our website visitors. We also use the information to determine which of our company's
              services are of interest to you.
              <br>
              We use HubSpot on the basis of your consent in accordance with Art. 6 para. 1 lit. a) GDPR. We use
              HubSpot Forms on the basis of our legitimate interest in accordance with Art. 6 para. 1 lit. f) GDPR,
              whereby our legitimate interest lies in the provision of any contact forms and simplified communication
              with interested parties. We would like to point out that when using HubSpot Forms, there is no personal
              tracking as with HubSpot itself.
            </li>
            <li>HubSpot is a software company from the USA. HubSpot Inc. relies on EU standard contractual clauses to
              legitimize the transfer of data to the USA:  <a href="https://legal.hubspot.com/dpa" target="_blank">https://legal.hubspot.com/dpa</a></li>
            <li>Further information on data protection can be found in HubSpot's privacy policy: <a href="https://legal.hubspot.com/privacy-policy" target="_blank">HubSpot privacy policy</a>,
             <a href="https://legal.hubspot.com/security" target="_blank">HubSpot information on the EU GDPR</a>,
              information on the <a href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser" target="_blank">Cookies used by HubSpot</a>.</li>
          </ul>
        </div>
      </div>
      <!--div 5 end-->
      <!--div 6 start-->
      <div>
        <li class="main-heading">
          <h4>Rights of data subjects</h4>
        </li>
        <hr>
        <div>
          <p>You have the right:</p>
          <ul class="mb-3" style="list-style-type:disc;">
            <li>to request information about your personal data processed by us in accordance with Art. 15 GDPR. In
              particular, you can request information about the processing purposes, the category of personal data, the
              categories of recipients to whom your data has been or will be disclosed, the planned storage period, the
              existence of a right to rectification, erasure, restriction of processing or objection, the existence of a
              right to lodge a complaint, the origin of your data if it was not collected by us, and the existence of
              automated decision-making including profiling and, if applicable, meaningful information about its
              details;</li>
            <li>in accordance with Art. 16 GDPR, to immediately request the correction of incorrect or incomplete
              personal data stored by us;</li>
            <li>in accordance with Art. 17 GDPR, to demand the deletion of your personal data stored by us, unless the
              processing is necessary to exercise the right to freedom of expression and information, to fulfill a legal
              obligation, for reasons of public interest or to assert, exercise or defend legal claims;</li>
            <li>in accordance with Art. 18 GDPR, to demand the restriction of the processing of your personal data if
              the accuracy of the data is disputed by you, the processing is unlawful but you refuse to delete it and we
              no longer need the data, but you need it to assert, exercise or defend legal claims or you have lodged an
              objection to the processing in accordance with Art. 21 GDPR;</li>
            <li>in accordance with Art. 20 GDPR, to receive your personal data that you have provided to us in a
              structured, commonly used and machine-readable format or to request that it be transmitted to another
              controller</li>
            <li>in accordance with Art. 7 para. 3 GDPR, to withdraw your consent once given to us at any time. As a
              result, we may no longer continue the data processing that was based on this consent in the future and
            </li>
            <li>to lodge a complaint with a supervisory authority in accordance with Art. 77 GDPR. As a rule, you can
              contact the supervisory authority of your usual place of residence or workplace or our company
              headquarters.</li>
          </ul>
        </div>
      </div>
      <!--div 6 end-->
      <!--div 7 start-->

      <div>
        <li class="main-heading">
          <h4>Right of objections</h4>
        </li>
        <hr>
        <div>
          <p>If your personal data is processed on the basis of legitimate interests in accordance with Art. 6 para. 1
            sentence 1 lit. f GDPR, you have the right to object to the processing of your personal data in accordance
            with Art. 21 GDPR, provided that there are reasons for this arising from your particular situation or the
            objection is directed against direct advertising. In the latter case, you have a general right to object,
            which will be implemented by us without specifying a particular situation.</p>
          <p>If you would like to exercise your right of revocation or objection, simply send an e-mail to
            <a href="mailto:datenschutz@sparrks.de"> datenschutz@sparrks.de</a>. </p>
        </div>
      </div>
      <!--div 7 end-->
      <!--div 8 start-->
      <div>
        <li class="main-heading">
          <h4>Data security</h4>
        </li>
        <hr>
        <p>We use the widespread SSL (Secure Socket Layer) method in conjunction with the highest level of encryption
          supported by your browser when you visit our website. As a rule, this is 256-bit encryption. If your browser
          does not support 256-bit encryption, we use 128-bit v3 technology instead. You can tell whether an individual
          page of our website is transmitted in encrypted form by the closed display of the key or lock symbol in the
          lower status bar of your browser.</p>
        <p>We also use suitable technical and organizational security measures to protect your data against accidental
          or intentional manipulation, partial or complete loss, destruction or unauthorized access by third parties.
          Our security measures are continuously improved in line with technological developments.</p>
      </div>
      <!--div 8 end-->
      <!--div 9 start-->
      <div>
        <li class="main-heading">
          <h4>Up-to-dateness and amendment of this privacy policy</h4>
        </li>
        <hr>
        <p>This privacy policy is currently valid and was last updated in March 2024.</p>
        <p>It may become necessary to amend this privacy policy as a result of the further development of our website
          and services or due to changes in legal or official requirements. The current privacy policy can be viewed at
          any time on the website at <a href="https://www.sparrks.io/datenschutzerklaerung" target="_blank"> https://www.sparrks.io/datenschutzerklaerung</a> can be retrieved and printed out by
          you.</p>
      </div>
    </ol>
  </div>
  <!--list div end-->
</div>
