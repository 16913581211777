import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from "rxjs/operators";

import { User } from '../models/auth.models';
import { CoacheeHttpService } from './coachee_services/http.service';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  private journeyId: string | null = null;

  constructor(
    private http: HttpClient,
    private _httpService: CoacheeHttpService) { }

  getAll() {
    return this.http.get<User[]>(`/api/login`);
  }

  register(user: User) {
    return this.http.post(`/users/register`, user);
  }

  sendApprovalRequest(token: string, action: string) {
    return this._httpService.sendApprovalRequest(token, action)
      .pipe(map(
        res => res
      ));
  }

  setJourneyId(id: string): void {
    this.journeyId = id;
  }

  getJourneyId(): string | null {
    return this.journeyId;
  }

  clearJourneyId(): void {
    this.journeyId = null;
  }

}
