import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {trackError} from "./tracker";

if (environment.production) {
  enableProdMode();
  // Disable logs
  if (window) {
    window['log'] = window.console.log;
    window['info'] = window.console.info;
    window['debug'] = window.console.debug;
  }
  
  window.console.log = () => { }
  window.console.warn = () => { }
  window.console.info = () => { }
  window.console.debug = () => { }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

window.onerror = function(event, source, lineno, colno, error) {
  trackError({
    msg: 'Unhandled error',
    event,
    source,
    lineno,
    colno,
    error
  })
}
