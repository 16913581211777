<div>
  <div class="consent-header">
    <h4>Consent or declaration of consent under data protection law for the use of personal data by the coach
    </h4>
  </div>

  <!--list div start-->
  <div class="consent-container">
    <ol class="terms-condition-block mb-3">
      <!--div 1 start-->
      <div>
        <li class="main-heading">
          <h4> Reason for data collection and type of data collected
          </h4>
        </li>
        <hr>
        <div>

          <p>Your employer enables you to take part in coaching arranged by SPARRKS Service GmbH, Schönhauser Allee
            167c, 10435 Berlin, at its own expense. For this purpose, your employer has concluded a contract with
            SPARRKS Service GmbH for the provision of coaching and coaches. The coach conducts such coaching sessions as
            a freelancer on the basis of a service contract on behalf of SPARRKS Service GmbH.</p>
          <p>You can register for a coaching session of interest to you via the SPARRKS Service GmbH booking portal.</p>
          <p>If you decide on coaching offered by a coach or if a suitable coach is suggested to you, SPARRKS Service
            GmbH will send you the following personal data so that the coach has a basis for deciding whether he or she
            can carry out the coaching:</p>
          <ul class="mb-2" style="list-style-type:disc;">
            <li>First name, last name,</li>
            <li>e-mail address, </li>
            <li>Language,</li>
            <li>employer,</li>
            <li>Position,</li>
            <li>professional experience,</li>
            <li>management experience,</li>
            <li>concerns.</li>
          </ul>
          <p>If the coach rejects the booking request, your personal data will be deleted by the coach immediately.</p>
          <p>If the coach accepts the booking, the aforementioned data will be stored and processed for the duration of
            the coaching for the purpose of carrying out the coaching. Your data will not be processed for any other
            purpose than the coaching. At the end of the coaching session, all personal data relating to you as the
            coachee will be deleted by the coach. Something else can be agreed in individual cases if you wish to
            receive further coaching and expressly want the notes to be kept for this purpose. In this case, a separate
            declaration of consent from you to the coach is required. The coaching is completed when you and your coach
            have agreed to end the coaching.</p>
          <p> The coaching itself is carried out via Microsoft Teams and is not recorded, so that no data storage or
            processing takes place here. Neither SPARRKS Service GmbH nor your employer have the possibility to access
            this in any way.
          </p>
          <p>However, the coach will take notes of your details during the coaching session. In individual cases, this
            may also involve particularly personal details and health data. If you entrust the coach with such
            information, it will either be noted down on paper, whereby this paper will not leave the office and will
            not be scanned, photographed or similar, or the coach will note down your details offline in a file on his
            computer. At the end of the coaching session, these notes will be deleted or destroyed immediately.
            Something else can be agreed in individual cases if you would like further coaching and expressly want the
            notes to be kept for this purpose. In this case, a separate declaration of consent from you to the coach is
            required. The coaching is completed when you and your coach have agreed to end the coaching.
          </p>
        </div>
      </div>

      <!--div 1 end-->
      <!--div 2 start-->
      <div>
        <li class="main-heading">
          <h4>Processors and other recipients of personal data</h4>
        </li>
        <hr>
        <p>
          Data processing is hosted by SPARRKS Service GmbH on the following servers:
        </p>
        <ul class="mb-3" style="list-style-type:disc;">
          <li>Google data center, Frankfurt</li>
        </ul>
      </div>
      <!--div 2 end-->
      <!--div 3 start-->
      <div>
        <li class="main-heading">
          <h4>Rights of the data subject: Information, correction, deletion and blocking, revocation</h4>
        </li>
        <hr>
        <div>
          <p>As a data subject affected by data processing, you have the following rights: </p>
          <ul class="mb-3" style="list-style-type:disc;">
            <li>Information about your data stored by us and its processing (Art. 15 GDPR)</li>
            <li>Correction of incorrect personal data (Art. 16 GDPR)</li>
            <li>Erasure of your data stored by us (Art. 17 GDPR)</li>
            <li>Restriction of data processing if we are not yet allowed to delete your data due to legal obligations
              (Art. 18 GDPR)</li>
            <li>Data portability if you have consented to the data processing or have concluded a contract with us (Art.
              20 GDPR)</li>
            <li>You also have the right to revoke or amend the consent or consents you have given at any time for the
              future without giving reasons (Art. 21 GDPR).</li>
            <li>You can lodge a complaint with a supervisory authority at any time, e.g. with the competent supervisory
              authority in the federal state of your place of residence or with the authority responsible for us as the
              controller.</li>
          </ul>
        </div>

      </div>
      <!--div 3 end-->
      <!--div 4 start-->
      <div>
        <li class="main-heading">
          <h4>Controller within the meaning of the GDPR and contact person for data processing</h4>
        </li>
        <hr>
        <div>
          <p>The person responsible for data processing is the coach you have selected.</p>
        </div>

      </div>
      <!--div 4 end-->
      <!--div 5 start-->
      <div>
        <li class="main-heading">
          <h4>Consequences of non-consent</h4>
        </li>
        <hr>
        <p>
          You have the right not to consent to this data processing by the coach. However, since the implementation of
          the coaching is dependent on the collection and processing of the aforementioned data, failure to give consent
          would preclude the use of the coaching.
        </p>
      </div>
      <!--div 5 end-->
      <!--div 6 start-->
      <div class="pb-5 pt-3">
        <div class="main-heading">
          <h4 class="text-center">Consent</h4>
        </div>
        <p>
          I declare my consent that the above-mentioned personal data about me may be stored by the coach for the
          purpose of conducting the coaching. I declare that I give this consent voluntarily. I am aware that I can
          revoke my consent at any time with effect for the future. In the event of revocation, the relevant data
          protection provisions shall apply.
        </p>
        <p>
          I hereby confirm that I consent to the collection and processing of my data by the coach as ticked above and
          that I have been informed of my rights.
        </p>
      </div>
    </ol>
  </div>
  <!--list div end-->
</div>
