<div>
  <div class="consent-header">
    <h4>Einwilligung bzw. datenschutzrechtliche Einverständniserklärung zur Verwendung personenbezogener Daten durch die
      SPARRKS Service GmbH</h4>
  </div>

  <!--list div start-->
  <div class="consent-container">
    <ol class="terms-condition-block mb-3">
      <!--div 1 start-->
      <div>
        <li class="main-heading">
          <h4> Grund für die Datenerhebung und Art der Erhobenen Daten
          </h4>
        </li>
        <hr>
        <div>
          <h4 class="secondary-heading">1.1 Durchführung des Coachings und der Anmeldung hierzu
          </h4>
          <p>Ihr Arbeitgeber ermöglicht es Ihnen, auf seine Kosten an einem durch die SPARRKS Service GmbH, Schönhauser
            Allee 167c, 10435 Berlin vermittelten Coaching teilzunehmen. Hierzu hat Ihr Arbeitgeber mit der SPAARKS
            Service GmbH einen Vertrag über die Vermittlung von Coachings geschlossen.</p>
          <p> Um sich für ein für Sie interessantes Coaching anzumelden, erhalten Sie Zugang zu einem Buchungsportal der
            SPARRKS Service GmbH, in welchem Sie zum Zwecke der Buchung und Zuordnung folgende Daten angeben müssen:
          </p>
          <ul class="mb-2" style="list-style-type:disc;">
            <li>Vorname, Nachname,</li>
            <li>E-Mail Adresse, </li>
            <li>Sprache,</li>
            <li>Arbeitgeber,</li>
            <li>Position,</li>
            <li>Berufserfahrung,</li>
            <li>Führungserfahrung,</li>
            <li>Anliegen.</li>
          </ul>
          <p>Diese Daten werden von der SPAARKS Service GmbH ausschließlich zur Durchführung der Anmeldung bei einem
            Coaching und zur Durchführung und Abrechnung des Coachings verwendet.</p>
          <p>Auf dem Buchungsportal können Sie sich für ein bestimmtes Coaching und auch für einen bestimmten Coach
            entscheiden oder sich geeignete Coaches vorschlagen lassen. Diese Coaches sind freie Mitarbeiter und führen
            die Coachings auf Basis von Dienstverträgen mit der SPARRKS Service GmbH durch. Die SPARRKS Service GmbH
            speichert sodann, bei welchem Coaching, bei welchem Coach Sie sich angemeldet haben.</p>
          <p>Wenn Sie sich in dem genannten Portal für einen Coach entschieden haben oder sich Coaches vorschlagen
            lassen, erhält dieser bzw. erhalten diese eine Buchungsanfrage, in welcher die SPAARKS Service GmbH die
            genannten personenbezogenen Daten an den potentiellen Coach übermittelt, damit dieser eine
            Entscheidungsgrundlage hat um zu entscheiden, ob er das Coaching übernehmen kann.</p>
          <p>Lehnt ein Coach die Buchungsanfrage ab, ist dieser verpflichtet, diese personenbezogenen Daten unverzüglich
            zu löschen.</p>
          <p>Nimmt er die Buchung an, wird er die genannten Daten zum Zwecke der Durchführung des Coachings für die
            Dauer des Coachings speichern. Er darf Ihre Daten zu keinem anderen Zweck als zur Erfüllung des konkreten
            Coachingauftrags verwenden; jede darüber hinausgehende Verarbeitung ist ihm durch die SPARRKS Service GmbH
            untersagt. Die Coaches wurden durch die SPARRKS Service GmbH zudem verpflichtet, nach Beendigung des
            Coachings alle persönlichen Daten, die sich auf die Coachees beziehen, zu löschen.</p>
        </div>
        <p>Das Coaching selbst wird via Microsoft Teams durchgeführt und wird nicht aufgezeichnet. Weder die SPAARKS
          Service GmbH noch Ihr Arbeitgeber haben die Möglichkeit, hierauf irgendwie zuzugreifen. Der jeweilige Coach
          kann, soweit dies zur Durchführung des Coachings erforderlich ist, während des Coachings Notizen über den
          Verlauf des Coachings und Angaben des Coachees – sei es auf Papier oder offline auf dem von ihm genutzten PC
          –anfertigen. Diese Daten werden weder an die SPAARKS Service GmbH noch an Ihren Arbeitgeber weitergegeben.
          Nach Beendigung des Coachings ist der Coach verpflichtet, auch diese Notizen zu löschen bzw. zu vernichten.
        </p>
        <p>Ihre personenbezogenen Daten werden gemäß den geltenden Datenschutzbestimmungen drei volle Kalenderjahre
          zuzüglich einer Pufferzeit von drei Monaten nach Ablauf des Jahres, in dem der Vertrag zwischen Ihrem
          Arbeitgeber und Sparrks vollständig erfüllt wurde, gelöscht.</p>
      </div>
      <div>
        <h4 class="secondary-heading">1.2 Feedback-Befragung </h4>
        <p> Wir bitten Sie zudem darum, der SPARRKS Service GmbH Ihr Einverständnis mit einer Datennutzung zum Zwecke
          der Durchführung einer Befragung nach Abschluss des Coachings zu geben. </p>
        <p> Wenn Sie Ihr Einverständnis hierzu erklären, werden einige Ihrer Daten auch nach Beendigung des Coachings
          und der Abrechnung gegenüber Ihrem Arbeitgeber zur Durchführung einer Feedback-Befragung gespeichert.</p>
        <p> In diesem Fall erhalten Sie nach Abschluss des Coachings via E-Mail einen Fragebogen, der sich auf die
          Qualität des Coachings und insbesondere darauf, ob das Coaching Ihre Erwartungen erfüllt hat und ob Sie mit
          dem Coach und den Inhalten des Coachings zufrieden waren, bezieht. Diese Befragung dient ausschließlich dem
          Zweck der internen Qualitätssicherung und dazu, Verbesserungen vorzunehmen, wenn hierfür Bedarf besteht.</p>
        <p>Zur Durchführung dieser Befragung würden, Ihr Einverständnis vorausgesetzt, über die Beendigung des Coachings
          hinaus folgende Daten von Ihnen gespeichert:</p>
        <ul class="mb-2" style="list-style-type:disc;">
          <li>Vorname, Nachname,</li>
          <li>E-Mail Adresse, </li>
          <li>Sprache,</li>
          <li>Arbeitgeber,</li>
          <li>Position,</li>
          <li>Berufserfahrung,</li>
          <li>Führungserfahrung,</li>
          <li>Anliegen.</li>
          <li>durchgeführtes Coaching und durchführender Coach.</li>
        </ul>
        <p>
          Ihre personenbezogenen Daten werden gemäß den geltenden Datenschutzbestimmungen drei volle Kalenderjahre
          zuzüglich einer Pufferzeit von drei Monaten nach Ablauf des Jahres, in dem der Vertrag zwischen Ihrem
          Arbeitgeber und Sparrks vollständig erfüllt wurde, gelöscht.
        </p>
      </div>
      <!--div 1 end-->
      <!--div 2 start-->
      <div>
        <li class="main-heading">
          <h4>Verarbeiter und andere Empfänger von personenbezogenen Daten</h4>
        </li>
        <hr>
        <p>
          Die Datenverarbeitung wird seitens der SPARRKS Service GmbH auf den folgenden Servern gehostet:
        </p>
        <ul class="mb-3" style="list-style-type:disc;">
          <li>Rechenzentrum von Google. Inc in Frankfurt</li>
        </ul>
      </div>
      <!--div 2 end-->
      <!--div 3 start-->
      <div>
        <li class="main-heading">
          <h4>Rechte des Betroffenen: Auskunft, Berichtigung, Löschung und Sperrung, Widerruf</h4>
        </li>
        <hr>
        <div>
          <p>Als von der Datenverarbeitung Betroffener haben sie folgende Rechte: </p>
          <ul class="mb-3" style="list-style-type:disc;">
            <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO)</li>
            <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO)</li>
            <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO)</li>
            <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht
              löschen dürfen (Art. 18 DSGVO)</li>
            <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns
              abgeschlossen haben (Art. 20 DSGVO)</li>
            <li>Sie haben zudem das Recht, die von Ihnen erteilte Einwilligung bzw. die von Ihnen erteilten
              Einwilligungen jederzeit ohne Angabe einer Begründung für die Zukunft zu widerrufen oder abzuändern (Art.
              21 DSGVO).</li>
            <li>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige
              Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle
              zuständige Behörde.</li>
          </ul>
        </div>

      </div>
      <!--div 3 end-->
      <!--div 4 start-->
      <div>
        <li class="main-heading">
          <h4>Verantwortlicher im Sinne der DSGVO und Ansprechpartner für die Datenverarbeitung</h4>
        </li>
        <hr>
        <div>
          <p> Verantwortlicher durch die Datenverarbeitung ist die SPARRKS Service GmbH. Zuständig ist hier Dr. Nicolas
            Stephan Co-Founder & Managing Director SPARRKS Service GmbH. Sie erreichen ihn unter +49 (0)30 52014961 oder
            per E-Mail unter <a href="mailto:nicolas.stephan@sparrks.de">nicolas.stephan@sparrks.de</a>.</p>
          <p> Bei der SPARRKS Service GmbH ist zudem ein externer Datenschutzbeauftragter benannt: Herr Prof. Dr.
            Reinhold Mauer, erreichbar unter <a href="mailto:datenschutz@sparrks.de"> datenschutz@sparrks.de</a>.</p>
        </div>

      </div>
      <!--div 4 end-->
      <!--div 5 start-->
      <div>
        <li class="main-heading">
          <h4>Folgen des Nicht-Unterzeichnens</h4>
        </li>
        <hr>
        <p>Sie haben das Recht, dieser Datenverarbeitung durch die SPARRKS Service GmbH nicht zuzustimmen.</p>
        <p>Da die Durchführung des Coachings jedoch auf die Erhebung und Verarbeitung genannter Daten angewiesen sind,
          würde ein Nichtankreuzen der diesbezüglichen Einwilligung und eine Nichtunterzeichnung eine Inanspruchnahme
          des Coachings ausschließen.</p>
        <p>Soweit Sie die Option, dass nach Durchführung des Coachings Ihre Daten zum Zwecke einer Befragung zum Zwecke
          der Qualitätssicherung genutzt werden dürfen, nicht ankreuzen, hat dies keine Folgen.</p>
      </div>
      <!--div 5 end-->
      <!--div 6 start-->
      <div class="pb-5 pt-3">
        <div class="main-heading">
          <h4 class="text-center">Einwilligung</h4>
        </div>
        <p> Ich erkläre meine Einwilligung, dass die vorgenannten personenbezogenen Daten über mich zum Zwecke der
          Durchführung des Coachings von der SPAARKS Service GmbH gespeichert werden dürfen. Ich erkläre, dass ich diese
          Einwilligung freiwillig abgebe. Mir ist bekannt, dass ich meine Einwilligung mit Wirkung für die Zukunft
          jederzeit widerrufen kann. Im Falle des Widerrufs finden die einschlägigen Datenschutzbestimmungen Anwendung.
        </p>
        <p>Hiermit erkläre ich zudem mein Einverständnis, dass die vorgenannten personenbezogenen Daten über mich über
          die Durchführung des Coachings hinaus zu speichern, um nach Abschluss des Coachings an einer Befragung über
          die Qualität des Coachings, seiner Inhalte und des Coaches teilzunehmen.</p>
        <p>Hiermit versichere ich, der Erhebung und der Verarbeitung seiner Daten durch die SPAARKS Service GmbH wie
          oben angekreuzt zuzustimmen und über seine Rechte belehrt worden zu sein.</p>
      </div>
    </ol>
  </div>
  <!--list div end-->
</div>
