export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },

  apiUrl : "https://eb37ad.sparrks.io/api/",
  forgotPaswordMailUrl : "https://b52e8c.sparrks.io/account",
  reschedulingTimeslotPageUrl :"https://b52e8c.sparrks.io/account/rescheduling-timeslot-page/"
};
