import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserProfileService } from 'src/app/core/services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-approval-handler',
  templateUrl: './approval-handler.component.html',
  styleUrls: ['./approval-handler.component.scss']
})
export class ApprovalHandlerComponent implements OnInit {
  statusMessage: string = '';
  loading: boolean = true;
  statusClass: string;
  flagvalue;
  countryName;
  valueset;
  element;
  cookieValue;
  language: string;
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
  ];

  constructor(
    private route: ActivatedRoute,
    private _userProfileService: UserProfileService,
    public cookiesService: CookieService,
    public languageService: LanguageService,
  ) { }

  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get('token');
    const action = this.route.snapshot.queryParamMap.get('action');
    this.initializeLanguage();

    if (token && action) {
      this.sendApprovalRequest(token, action);
    } else {
      this.statusMessage = 'Invalid request';
      this.statusClass = 'error';
      this.statusClass = 'rejected';
      this.loading = false;
    }
  }

  initializeLanguage(){
     this.element = document.documentElement;
     this.cookieValue = this.cookiesService.get('lang');
     const val = this.listLang.filter(x => x.lang === this.cookieValue);
     this.countryName = val.map(element => element.text);
     if (val.length === 0) {
       if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
     } else {
       this.flagvalue = val.map(element => element.flag);
     }

     if (this.cookiesService.check('lang')) {
       let browserLang = this.cookiesService.get('lang');
       if (browserLang == 'en') {
         this.language = 'english';
       } else {
         this.language = 'german';
       }
     }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
    if (this.statusClass === 'approved') {
      this.statusMessage = lang === 'en' ? 'Coaching Request Approved' : 'Coachinganfrage genehmigt';
    } else if (this.statusClass === 'rejected') {
      this.statusMessage = lang === 'en' ? 'Coaching Request Denied' : 'Coachinganfrage abgelehnt';
    } else if ( this.statusClass === 'link-expired' ) {
      this.statusMessage = lang === 'en' ? 'This link is expired' : 'Dieser Link ist nicht mehr gültig';
    } else {
      this.statusMessage = lang === 'en' ? 'An unexpected error occurred' : 'Ein unerwarteter Fehler ist aufgetreten';
    }
  }

  sendApprovalRequest(token: string, action: string): void {
    this._userProfileService.sendApprovalRequest(token, action).subscribe(
      (response) => {
        if (response.status === 'approved') {
          this.statusMessage = this.cookieValue === 'en' ? 'Coaching Request Approved' : 'Coachinganfrage genehmigt';
          this.statusClass = 'approved';
        } else if (response.status === 'rejected') {
          this.statusMessage = this.cookieValue === 'en' ? 'Coaching Request Denied' : 'Coachinganfrage abgelehnt';
          this.statusClass = 'rejected';
        }
        this.loading = false;
      },
      (error) => {
        if (error === 'Error making an API requestConflict') {
          this.statusMessage =  this.cookieValue === 'en' ? 'This link is expired' : 'Dieser Link ist nicht mehr gültig';
          this.statusClass = 'link-expired';
        } else {
          this.statusMessage = this.cookieValue === 'en' ? 'An unexpected error occurred' : 'Ein unerwarteter Fehler ist aufgetreten';
          this.statusClass = 'error';
        }
        this.loading = false;
      }
    );
  }

}
