import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import {trackError} from "../../../../tracker";

@Injectable({ providedIn: 'root' })

export class CoachHttpService {
  DeleteFile(file_id: any) {
    throw new Error("Method not implemented.");
  }

  constructor(private _http: HttpClient, private router: Router) { }

  checkUnauthorized(err) {
    if (err?.status === 401) {
      this.router.navigate(['account/coach-login']);
      throw "unauthorized"
    }
  }

  get<R>(path: string) {
    let headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return this._http.get(path, {headers}).pipe(
      map(r => r as R),
      catchError((err) => {
        this.checkUnauthorized(err)
          if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
          }
        trackFetchError('GET', path, err)
          throw 'Error making an API request' + err; // Throw err.
      })
    )
  }

  post<R>(path: string, body: any) {
    let headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return this._http.post(path, body, {headers}).pipe(
      map(r => r as R),
      catchError((err) => {
        this.checkUnauthorized(err)
        if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
        }
        trackFetchError('POST', path, err, body)
        throw 'Error making an API request' + err; // Throw err.
      })
    )
  }

  put<R>(path: string, body: any) {
    let headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return this._http.put(path, body, {headers}).pipe(
      map(r => r as R),
      catchError((err) => {
        this.checkUnauthorized(err)
        if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
        }
        trackFetchError('PUT', path, err, body)
        throw 'Error making an API request' + err; // Throw err.
      })
    )
  }

  //login api
  postCoachLogin(login_info) {
    return this.post<any>('/api/v1/coach/authenticate_login', login_info)
  }


  // sendPasswordToEmail
  sendPasswordToEmail(email_info) {
    return this.post<any>('/api/v1/coach/forgot_password', email_info);
  }

  verifyPasswordResetToken(token_info) {
    return this.post<any>('/api/v1/coach/decrypt_reset_password_data', token_info);
  }

  verifyPasswordSetToken(token_info) {
    return this.post<any>('/api/v1/coach/decrypt_setup_password_data', token_info);
  }

  verifyTimeSlotConfirmToken(token_info) {
    return this.post<any>('/api/v1/coach/decrypt_time_slot_data', token_info);
  }

  verifyReschedulingTimeSlotConfirmToken(token_info) {
    return this.post<any>('/api/v1/coach/decrypt_rescheduling_request_data', token_info);
  }

  resetPassword(password_details) {
    let headers = new HttpHeaders().set('token', localStorage.getItem('resetpasswordToken'));
    headers.set("Accept", "application/json")
    const path = '/api/v1/coach/reset_password'
    return this._http.post(path, password_details, {headers}).pipe(
      map(r => r as any),
      catchError((err) => {
        this.checkUnauthorized(err)
        if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
        }
        trackFetchError('POST', path, err)
        throw 'Error making an API request' + err; // Throw err.
      })
    )
  }

  setPassword(password_details) {
    let headers = new HttpHeaders().set('token', localStorage.getItem('setpasswordToken'));
    headers.set("Accept", "application/json")
    const path = '/api/v1/coach/setup_password'
    return this._http.post(path, password_details, {headers}).pipe(
      map(r => r as any),
      catchError((err) => {
        this.checkUnauthorized(err)
        if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
        }
        trackFetchError('POST', path, err)
        throw 'Error making an API request' + err; // Throw err.
      })
    )
  }

  confirmedTimeSlot(timeslot_details) {
    let headers = new HttpHeaders().set('token', localStorage.getItem('timeslot_confirm_page_token'));
    headers.set("Accept", "application/json")
    const path = '/api/v1/coach_assign_timeslot'
    return this._http.post(path, timeslot_details, {headers}).pipe(
      map(r => r as any),
      catchError((err) => {
        this.checkUnauthorized(err)
        if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
        }
        trackFetchError('POST', path, err, timeslot_details)
        throw 'Error making an API request' + err; // Throw err.
      })
    )
  }

  confirmedReschedulingTimeSlot(timeslot_details) {
    let headers = new HttpHeaders().set('token', localStorage.getItem('rescheduled_timeslot_confirm_page_token'));
    headers.set("Accept", "application/json")
    const path = '/api/v1/coach/post_assign_time_slot_for_rescheduling_request'
    return this._http.post(path, timeslot_details, {headers}).pipe(
      map(r => r as any),
      catchError((err) => {
        this.checkUnauthorized(err)
        if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
        }
        trackFetchError('POST', path, err, timeslot_details)
        throw 'Error making an API request' + err; // Throw err.
      })
    )
  }

  getCoachJourneys(coachDetails) {
    let endPoint = `/api/v1/coach/journeys?offset=${coachDetails.offset}&limit=${coachDetails.limit}`;
    if (coachDetails.coachee) endPoint += `&coachee=${coachDetails.coachee}`;
    if (coachDetails.usecase) endPoint += `&usecase=${coachDetails.usecase}`;
    if (coachDetails.topic) endPoint += `&topic=${coachDetails.topic}`;
    if (coachDetails.date) endPoint += `&date=${coachDetails.date.toISOString()}`;
    if (coachDetails.completed !== undefined) endPoint += `&completed=${coachDetails.completed}`;
    return this.get<any>(endPoint)
  }

  fetchCoachJourneyFilesList(journey_id) {
    return this.post<any>('/api/v1/coach/post_fetch_coach_journey_files_list', journey_id)
  }

  coachUploadJourneyFiles(coach_journey_details) {
    return this.post<any>('/api/v1/coach/post_coach_upload_journey_files', coach_journey_details);
  }

  fetchCoachProfileDetails(coach_id) {
    return this.post<any>('/api/v1/coach/view_edit_coach', coach_id);
  }


  coachPasswordUpdate(password) {
    return this.post<any>('/api/v1/coach/post_update_coach_password', password);
  }

  bookNextSession(journeyId, session_details){
    let endPoint =  `/api/v1/coach/journeys/${journeyId}/book-next-session`;
    return this.post<any>(endPoint, session_details)
  }

  endJourney(session_id) {
    return this.post<any>('/api/v1/coach/post_end_journey_and_add_key_takeaways', session_id)
  }

  coachJoinSession(journeyId, sessionId) {
    let endPoint = `/api/v1/coach/journeys/${journeyId}/sessions/${sessionId}/join`;
    return this.post<any>(endPoint, {})
  }

  fetchPrepQuestAndAnswers(session_id) {
    return this.post<any>('/api/v1/coach/post_fetch_session_preparation_question_and_answer', session_id);
  }

  fetchSessionEndedDetails(journey_id) {
    return this.post<any>('/api/v1/coach/post_fetch_sessions_ended_list', journey_id)
  }

  getCoachQualitricsQuestions(coach_id_and_language) {
    return this.post<any>('/api/v1/coach/post_fetch_coach_survey_questions', coach_id_and_language);
  }

  storeCoachQualitricsQuestionsAnswers(question_answers) {
    return this.post<any>('/api/v1/coach/post_save_coach_survey_questions_answers', question_answers)
  }

  reschedulingSession(rescheduling_info) {
    return this.post<any>('/api/v1/coach/post_coach_save_rescheduling_request', rescheduling_info)
  }

  getActionItemForCoachee(session_id) {
    return this.post<any>('/api/v1/coach/post_fetch_session_action_item_json', session_id)
  }

  getKeyTakeawaysForCoachee(journey_id) {
    return this.post<any>('/api/v1/coach/post_fetch_journey_key_takeway_json', journey_id);
  }

  saveActionItemsEditedInCoachingHistory(journey_details) {
    return this.post<any>('/api/v1/coach/post_add_actionitems', journey_details);
  }

  saveKeytakewaysEditedInCoachingHistory(journey_details) {
    return this.post<any>('/api/v1/coach/post_add_keytakeways', journey_details);
  }

  saveNotes(notes) {
    return this.post<any>('/api/v1/coach/post_save_session_notes', notes);
  }

  Delete_coach_file(file_id) {
    return this.post<any>('/api/v1/coach/delete_coach_file', file_id)
  }

  markDocumentAsSeen(journeyId, fileId) {
    let endpoint = `/api/v2/coach/journeys/${journeyId}/files/${fileId}/seen`;
    return this.post<any>(endpoint, '')
  }

  getAnalyticsConfiguration() {
    return this.get<any>('/api/v2/coach/analytics');
  }
}

function trackFetchError(method: string, path: string,  err: any, bodyData: any = null) {
  let body = bodyData
  if (body instanceof FormData) {
    body = JSON.parse(JSON.stringify(body))
  }
  trackError({
    msg: `unable to ${method} ${path}`,
    data: {
      status: err.status,
      url: err.url,
      message: err.message,
      error: err.error,
      body
    }
  })
}
