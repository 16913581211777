import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {trackError} from "../../../../tracker";

@Injectable({ providedIn: 'root' })

export class CoacheeHttpService {
  constructor(private _http: HttpClient, private router: Router) { }

  SERVER_URL = environment.apiUrl;

  checkUnauthorized(err) {
    if (err?.status === 401) {
      this.router.navigate(['account/coachee-login']);
      throw "unauthorized"
    }
  }

  get<R>(path: string) {
    let headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return this._http.get(path, {headers}).pipe(
      map(r => r as R),
      catchError((err) => {
        this.checkUnauthorized(err)
          if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
          }
        trackFetchError('GET', path, err)
          throw 'Error making an API request' + err; // Throw err.
      })
    )
  }

  post<R>(path: string, body: any) {
    let headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return this._http.post(path, body, {headers}).pipe(
      map(r => r as R),
      catchError((err) => {
        this.checkUnauthorized(err)
        if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
        }
        trackFetchError('POST', path, err, body)
        throw 'Error making an API request' + err; // Throw err.
      })
    )
  }

  put<R>(path: string, body: any) {
    let headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return this._http.put(path, body, {headers}).pipe(
      map(r => r as R),
      catchError((err) => {
        this.checkUnauthorized(err)
        if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
        }
        trackFetchError('PUT', path, err, body)
        throw 'Error making an API request' + err; // Throw err.
      })
    )
  }

  //login api
  postCoacheeLogin(login_info) {
    return this.post<any>('/api/v1/coachee_authenticate_login', login_info);
  }

  coacheeLogout() {
    return this.post<any>('/api/v1/coachee/logout', null)
  }

  postCoacheeEmailCheck(login_info) {
    return this.post<any>('/api/v1/domain_landing_page_data', login_info)
  }


  // sendPasswordToEmail
  sendPasswordToEmail(email_info) {
    return this.post<any>('/api/v1/coachee_forgot_password', email_info)
  }

  //coachee set password
  verifyPasswordResetToken(token_info) {
    return this.post<any>('/api/v1/coachee_decrypt_reset_password_data', token_info)
  }

  verifyPasswordSetToken(token_info) {
    return this.post<any>('/api/v1/coachee_decrypt_setup_password_data', token_info)
  }

  resetPassword(password_details) {
    return this.post<any>('/api/v1/coachee_reset_password', password_details)
  }

  setPassword(password_details) {
    return this.post<any>('/api/v1/coachee_setup_password', password_details)
  }

  createCoacheeAccount(registration_details) {
    return this.post<any>('/api/v2/coachee/register', registration_details)
  }

  getUsecasesAndTopics(coachee_details) {
    return this.post<any>('/api/v1/get_usecases_assigned_for_coachee_to_specific_program', coachee_details)
  }

  bookSession(session_details) {
    return this.post<any>('/api/v1/post_save_first_book_session_data', session_details);
  }

  getCoacheeCurrentJourney() {
    return this.get<any>('/api/v1/coachee/current-journey');
}

coacheeJourneys(coacheeDetails) {
  let endPoint = `/api/v1/coachee/journeys?offset=${coacheeDetails.offset}&limit=${coacheeDetails.limit}`;
  if (coacheeDetails.coach) endPoint += `&coach=${coacheeDetails.coach}`;
  if (coacheeDetails.usecase) endPoint += `&usecase=${coacheeDetails.usecase}`;
  if (coacheeDetails.topic) endPoint += `&topic=${coacheeDetails.topic}`;
  if (coacheeDetails.date) endPoint += `&date=${coacheeDetails.date.toISOString()}`;
  if (coacheeDetails.current !== undefined) endPoint += `&completed=${coacheeDetails.completed}`;
  if (coacheeDetails.current !== undefined) endPoint += `&latest=${coacheeDetails.latest}`;
  return this.get<any>(endPoint)
}

  coacheeUploadJourneyFiles(coachee_journey_details) {
    return this.post<any>('/api/v1/post_coachee_upload_journey_files', coachee_journey_details)
  }

  coacheeJoinSession(journeyId, sessionId) {
    return this.post<any>(`/api/v1/coachee/journeys/${journeyId}/sessions/${sessionId}/join`, {})
  }

  coacheeProfileUpdate(profile_details) {
    return this.post<any>('/api/v1/edit_coachee_details', profile_details)
  }

  coacheePasswordUpdate(password) {
    return this.post<any>('/api/v1/post_update_coachee_password', password)
  }

  fetchCoacheeProfileDetails(coachee_id) {
    return this.post<any>('/api/v1/view_edit_coachee', coachee_id)
  }

  editCoacheeProfileDetails(coachee_details) {
    return this.post<any>('/api/v1/edit_coachee_details', coachee_details);
  }

  savePrepQuestAnsw(info) {
    return this.post<any>('/api/v1/post_save_session_prepration_question_and_answers', info)
  }

  sendPrepQuestAnsw(id, info) {
    return this.put<any>(`/api/v1/coachee/sessions/${id}/preparation-questions/answers`, info)
  }

  fetchSessionEndedDetails(journey_id) {
    return this.post<any>('/api/v1/post_fetch_sessions_ended_list', journey_id)
  }

  fetchPrepQuestAndAnswers(session_id) {
    return this.post<any>('/api/v1/post_fetch_session_preparation_question_and_answer', session_id)
  }

  coacheeCoahingHistoryDetails(coachee_id) {
    return this.post<any>('/api/v1/post_coachee_fetch_completed_journey_sessions_list', coachee_id)
  }

  getCoacheeQualitricsQuestions(coachee_id_and_language) {
    return this.post<any>('/api/v1/post_fetch_coachee_survey_questions', coachee_id_and_language);
  }

  storeCoacheeQualitricsQuestionsAnswers(question_answers) {
    return this.post<any>('/api/v1/post_save_coachee_survey_questions_answers', question_answers)
  }

  getActionItemForCoachee(session_id) {
    return this.post<any>('/api/v1/coachee/post_fetch_session_action_item_json', session_id)
  }

  getKeyTakeawaysForCoachee(journey_info) {
    return this.post<any>('/api/v1/coachee/post_fetch_journey_key_takeway_json', journey_info)
  }

  reschedulingSession(rescheduling_info) {
    return this.post<any>('/api/v1/post_coachee_save_rescheduling_request', rescheduling_info)
  }

  fetchCoacheeSurveyAnswers(journey_id) {
    return this.post<any>('/api/v1/post_fetch_coachee_survey_answers', journey_id)
  }

  fetchFlagToCheckIsCongratsPageOpened(journey_id) {
    return this.post<any>('/api/v1/post_fetch_not_seen_congrats_page_journeys', journey_id)
  }

  onCloseCongratulationPage(journey_id) {
    return this.post<any>('/api/v1/post_close_congrats_page', journey_id)
  }

  getCountryList() {
    return this.get<any>('/api/v1/coachee/get_countries_with_code_list');
  }

  fetchMatchingInprogressSessions(coachee_id) {
    return this.post<any>('/api/v1/post_fetch_matching_in_progress_sessions', {})
  }

  fetchCoacheeActiveJourney(coachee_id) {
    return this.post<any>('/api/v1/post_fetch_active_journeys', {})
  }

  markDocumentAsSeen(journeyId, fileId) {
    return this.post<any>(`/api/v2/coachee/journeys/${journeyId}/files/${fileId}/seen`, '')
  }

  searchCoachingHistory(value) {
    return this.post<any>('/api', value)
  }

  saveNotes(notes) {
    return this.post<any>('/api/v1/coachee/post_save_session_notes', notes)
  }

  getNotesDetailsForCoachee(notes) {
    return this.post<any>('/api/v1/post_fetch_session_notes', notes)
  }

  delete_coachee_file(file_id) {
    return this.post<any>('/api/v1/coachee/delete_coachee_file', file_id)
  }

  sendApprovalRequest(token: string, action: string): Observable<PostApprovalResponse> {
    return this.post<PostApprovalResponse>(`/api/v1/approvals/coaching?token=${token}&action=${action}`, {})
  }
}

export type PostApprovalResponse = {
  status: 'approved'|'rejected'
}

function trackFetchError(method: string, path: string,  err: any, bodyData: any = null) {
  let body = bodyData
  if (bodyData instanceof FormData) {
    let data: any = {}
    bodyData.forEach((v, k) => data[k] = v)
    body = data
  }
  trackError({
    msg: `unable to ${method} ${path}`,
    data: {
      status: err.status,
      url: err.url,
      message: err.message,
      error: err.error,
      body
    }
  })
}
