import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CoachService } from 'src/app/core/services/coach_services/coach.service';
import { CoacheeService } from 'src/app/core/services/coachee_services/coachee.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})


export class SidebarComponent implements OnInit {
  isCoach: string;
  isCoachee: string;
  language: string = 'english';
  sidebarLogo: string;
  isAnalyticsEnabled: number;

  constructor(
    public _cookiesService: CookieService,
    private _coacheeService: CoacheeService,
    private _coachService: CoachService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('rememberMe')) {
      this.isCoach = localStorage.getItem('isCoach');
      this.isCoachee = localStorage.getItem('isCoachee');
    } else {
      this.isCoach = sessionStorage.getItem('isCoach');
      this.isCoachee = sessionStorage.getItem('isCoachee');
    }

    if (this._cookiesService.check('lang')) {
      let browserLang = this._cookiesService.get('lang');
      if (browserLang == 'en') {
        this.language = 'english';
      } else {
        this.language = 'german';
      }
    }

    if (this.isCoachee === 'true') {
      this._coacheeService.getSidebarLogo().subscribe(data => {
        this.sidebarLogo = data;
      });
    } else {
      this.sidebarLogo = null;
      this._coachService.getAnalyticsConfiguration().subscribe(
        data => {
          this.isAnalyticsEnabled = data.enabled;
        },
        error => {
          console.error('Error making an API request', error);
        }
      );
    }
  }
}
