<div>
  <div class="consent-header">
    <h4>Datenschutzrichtlinien</h4>
    <h4>Sparrks Service GmbH</h4>
  </div>

  <!--list div start-->
  <div>
    <ol class="terms-condition-block">
      <!--div 1 start-->
      <div>
        <li class="main-heading mt-3">
          <h4>Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen
            Datenschutzbeauftragten</h4>
        </li>
        <hr>
        <p>Diese Datenschutz-Information gilt für die Datenverarbeitung durch:</p>
        <p>Verantwortlicher: SPARRKS Service GmbH („sparrks“), Schönhauser Allee 167c, 10435 Berlin,
          Amtsgericht Charlottenburg (Berlin),HRB 226047 B, vertreten durch die Geschäftsführer Jakob Hansen und Dr.
          Nicolas Stephan</p>
        <p>Der externe Datenschutzbeauftragte von sparrks ist Prof. Dr. Reinhold Mauer, erreichbar unter <a
            href="mailto:datenschutz@sparrks.de"> datenschutz@sparrks.de</a>.</p>
      </div>
      <!--div 1 end-->
      <!--div 2 start-->
      <div>
        <li class="main-heading">
          <h4>Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h4>
        </li>
        <hr>
        <div>
          <h4 class="secondary-heading">a) Beim Besuch der Website
          </h4>
          <p>Beim Aufrufen unserer Website www.sparrks.io werden durch den auf Ihrem Endgerät zum Einsatz kommenden
            Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden
            temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und
            bis zur automatisierten Löschung gespeichert:
          </p>
          <ul class="mb-2" style="list-style-type:disc;">
            <li>IP-Adresse des anfragenden Rechners,</li>
            <li>Datum und Uhrzeit des Zugriffs,</li>
            <li>Name und URL der abgerufenen Datei,</li>
            <li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
            <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.
            </li>
          </ul>
          <p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
          </p>
          <ul class="mb-2" style="list-style-type:disc;">
            <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</li>
            <li>Gewährleistung einer komfortablen Nutzung unserer Website,</li>
            <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
            <li>zu weiteren administrativen Zwecken.
            </li>
          </ul>
          <p>Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes
            Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung.
          </p>
          <p>Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein. Nähere
            Erläuterungen dazu erhalten Sie unter den Ziff. 4 und 5 dieser Datenschutzerklärung.</p>
        </div>
        <div>
          <h4 class="secondary-heading">b) Bei Nutzung unseres Kontaktformulars
          </h4>
          <p>Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Website
            bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse
            erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese beantworten zu können. Weitere
            Angaben können freiwillig getätigt werden.</p>
          <p>Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO
            auf Grundlage Ihrer freiwillig erteilten Einwilligung.
          <p>Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung
            der von Ihnen gestellten Anfrage automatisch gelöscht.</p>
        </div>
      </div>
      <!--div 2 end-->
      <!--div 3 start-->
      <div>
        <li class="main-heading">
          <h4>Weitergabe von Daten</h4>
        </li>
        <hr>

        <div>
          <p>Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken
            findet nicht statt.
          </p>
          <p>Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>
          <ul class="mb-3" style="list-style-type:disc;">
            <li>Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,
            </li>
            <li>die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von
              Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes
              schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,</li>
            <li>für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche
              Verpflichtung besteht, sowie,</li>
            <li>dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von
              Vertragsverhältnissen mit Ihnen erforderlich ist.
            </li>
          </ul>
        </div>
      </div>
      <!--div 3 end-->
      <!--div 4 start-->
      <div>
        <li class="main-heading">
          <h4>Cookies</h4>
        </li>

        <p>
          Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser
          automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie
          unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner
          oder sonstige Schadsoftware.
        </p>
        <p>
          In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten
          Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität
          erhalten.
        </p>
        <p>Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten.
          So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits
          besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.</p>
        <p>Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für
          einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite
          erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und
          welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.</p>
        <p>Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke
          der Optimierung unseres Angebotes für Sie auszuwerten (siehe Ziff. 5). Diese Cookies ermöglichen es uns, bei
          einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies
          werden nach einer jeweils definierten Zeit automatisch gelöscht.
        </p>
        <p>Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten
          Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
        </p>
        <p>Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass
          keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie
          angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle
          Funktionen unserer Website nutzen können.</p>
      </div>

      <!--div 4 end-->
      <!--div 5 start-->
      <div>
        <li class="main-heading">
          <h4 class="mt-2">Analyse-Tools</h4>
        </li>
        <hr>
        <div>
          <h4 class="secondary-heading"> a) Tracking-Tools</h4>
          <p>
            Die im Folgenden aufgeführten und von uns eingesetzten Tracking-Maßnahmen werden auf Grundlage des Art. 6
            Abs. 1 S. 1 lit. f DSGVO durchgeführt. Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine
            bedarfsgerechte Gestaltung und die fortlaufende Optimierung unserer Webseite sicherstellen. Zum anderen
            setzen wir die Tracking-Maßnahmen ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum
            Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Interessen sind als berechtigt im Sinne
            der vorgenannten Vorschrift anzusehen.
          </p>
          <p>Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus den entsprechenden Tracking-Tools zu
            entnehmen.</p>
          <h4 class="secondary-heading">Google Tag Manager</h4>
          <ul style="list-style-type:disc;">
            <li>Welche personenbezogenen Daten werden erhoben und in welchem Umfang werden diese verarbeitet? Auf
              unserer Seite verwenden wir den Service des Unternehmens Google Ireland Limited, Gordon House, Barrow
              Street, Dublin 4, Irland (nachfolgend: Google Tag Manager). Google Tag Manager bietet eine technische
              Plattform um andere Webservices und Webtracking-Programme mittels sogenannter „Tags“ auszuführen und
              gebündelt steuern zu können. Google Tag Manager speichert in diesem Zusammenhang Cookies auf Ihrem
              Computer und analysiert, soweit Webtracking Tools mittels Google-Tag-Manager ausgeführt werden, Ihr
              Surfverhalten (sogenanntes „tracken“). Diese von einzelnen in Google-Tag-Manager eingebundenen Tags
              gesendeten Daten werden von Google Tag Manager unter einer einheitlichen Benutzeroberfläche
              zusammengeführt, gespeichert und verarbeitet. Alle eingebundenen „Tags“ werden in dieser
              Datenschutzerklärung nochmals gesondert aufgeführt. Nähere Informationen zum Datenschutz der in Google Tag
              Manager eingebundenen Tools finden Sie in dem jeweiligen Abschnitt dieser Datenschutzerklärung. Im Rahmen
              der Benutzung unserer Webseite bei aktivierter Einbindung von Tags von Google-Tag-Manager werden dabei
              Daten, wie insbesondere Ihre IP-Adresse und Ihre Nutzeraktivitäten an Server des Unternehmens Google
              Ireland Limited übertragen. Bzgl. der mittels Google Tag Manager eingebundenen Webservices gelten die
              Regelungen in dem jeweiligen Abschnitt dieser Datenschutzerklärung. Die in Google Tag Manager verwendeten
              Tracking-Tools stellen durch eine IP-Anonymisierung des Quellcodes sicher, dass die IP-Adresse von Google
              Tag Manager vor der Übertragung anonymisiert wird. Hierbei wird Google Tag Manager nur die anonymisierte
              Erfassung von IP-Adressen (sog. IP-Masking) ermöglicht.
            </li>
            <li>Rechtsgrundlage für die Verarbeitung personenbezogener Daten Rechtsgrundlage für die Datenverarbeitung
              ist gemäß Art. 6 Abs. 1 lit. a DSGVO Ihre Einwilligung in unserem Hinweisbanner bezüglich der Nutzung von
              Cookies und Webtracking (Einwilligung durch eindeutige bestätigende Handlung bzw. Verhaltensweise).</li>
            <li>Zweck der Datenverarbeitung Im unserem Auftrag wird Google die mittels Google-Tag Manager gewonnenen
              Informationen nutzen, um Ihren Besuch auf dieser Internetseite auszuwerten, Reports über die
              Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung
              verbundene Dienstleistungen gegenüber uns zu erbringen.</li>
            <li>Dauer der Speicherung Google wird die für die Funktion von Google-Tag-Manager relevanten Daten solange
              speichern, wie es notwendig ist, um den gebuchten Webservice zu erfüllen. Die Datenerhebung und
              Speicherung erfolgt anonymisiert. Soweit doch Personenbezug bestehen sollte, werden die Daten unverzüglich
              gelöscht, soweit diese keinen gesetzlichen Aufbewahrungspflichten unterfallen. In jedem Fall erfolgt die
              Löschung nach Ablauf der Aufbewahrungspflicht.</li>
            <li> Widerspruchs- und Löschungsmöglichkeit Sie können die Erfassung und Weiterleitung der personenbezogenen
              Daten an Google (insb. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern,
              indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren, einen Script-Blocker in Ihrem
              Browser installieren oder die „Do Not Track” Einstellung Ihres Browsers aktivieren. Sie können darüber
              hinaus die Erfassung der durch den Google-Cookie erzeugten und auf Ihre Nutzung der Website bezogenen
              Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
              indem Sie das unter dem folgenden Link
              <a href="http://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank">http://tools.google.com/dlpage/gaoptout?hl=d</a> verfügbare Browser-Plug-In
              herunterladen und installieren. Die Sicherheits- und Datenschutzgrundsätze von Google finden Sie unter
              <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>.
            </li>
          </ul>
        </div>
        <br>
        <div>
          <h4 class="secondary-heading"> Google-Analytics</h4>
          <ul class="mb-3" style="list-style-type:disc;">

            <li>Umfang der Verarbeitung personenbezogener Daten Auf unserer Seite verwenden wir den Webtracking-Service
              des Unternehmens Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (nachfolgend:
              Google-Analytics). Google-Analytics nutzt im Rahmen des Webtrackings Cookies, die auf Ihrem Computer
              gespeichert werden und die eine Analyse der Benutzung unserer Website und Ihres Surfverhaltens ermöglichen
              (sogenanntes Tracken). Wir führen diese Analyse auf Basis des Tracking-Services von Google-Analytics
              durch, um unser Internetangebot ständig zu optimieren und besser verfügbar zu machen. Im Rahmen der
              Benutzung unserer Webseite werden dabei Daten, wie insbesondere Ihre IP-Adresse und Ihre Nutzeraktivitäten
              an Server des Unternehmens Google Ireland Limited übertragen. Wir führen diese Analyse auf Basis des
              Tracking-Services von Google durch, um unser Internetangebot ständig zu optimieren und besser verfügbar zu
              machen. Ebenso benötigen wir das Webtracking aus Sicherheitsgründen. Durch das Webtracking können wir
              nachverfolgen, ob Dritte unsere Website angreifen. Durch die Informationen des Webtrackers können wir
              wirksame Gegenmaßnahmen ergreifen und die durch uns verarbeiteten personenbezogenen Daten vor diesen
              Cyberangriffen schützen. Durch die Aktivierung der IP-Anonymisierung innerhalb des Google-Analytics
              Tracking-Codes dieser Internetseite, wird Ihre IP-Adresse von Google-Analytics vor der Übertragung
              anonymisiert. Diese Website nutzt einen Google-Analytics-Tracking-Code, der um den Operator
              gat._anonymizeIp(); erweitert wurde, um nur eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking)
              zu ermöglichen.
            </li>
            <li>Rechtsgrundlage für die Verarbeitung personenbezogener Daten Rechtsgrundlage für die Datenverarbeitung
              ist gemäß Art. 6 Abs. 1 lit. a DSGVO Ihre Einwilligung in unserem Hinweisbanner bezüglich der Nutzung von
              Cookies und Webtracking (Einwilligung durch eindeutige bestätigende Handlung bzw. Verhaltensweise).</li>
            <li>Zweck der Datenverarbeitung In unserem Auftrag wird Google diese Informationen nutzen, um Ihren Besuch
              auf dieser Internetseite auszuwerten, Reports über die Websiteaktivitäten zusammenzustellen und um
              weitere, mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen, gegenüber uns zu
              erbringen. Ebenso benötigen wir das Webtracking aus Sicherheitsgründen. Durch das Webtracking können wir
              nachverfolgen, ob Dritte unsere Website angreifen. Durch die Informationen des Webtrackers können wir
              wirksame Gegenmaßnahmen ergreifen und die durch uns verarbeiteten personenbezogenen Daten vor diesen
              Cyberangriffen schützen.
            </li>
            <li>Dauer der Speicherung Google wird die für die Bereitstellung des Webtracking relevanten Daten solange
              speichern, wie es notwendig ist, um den gebuchten Webservice zu erfüllen. Die Datenerhebung und
              Speicherung erfolgt anonymisiert. Soweit doch Personenbezug bestehen sollte, werden die Daten unverzüglich
              gelöscht, soweit diese keinen gesetzlichen Aufbewahrungspflichten unterfallen. In jedem Fall erfolgt die
              Löschung nach Ablauf der Aufbewahrungspflicht.</li>
            <li>Widerspruchs- und Löschungsmöglichkeiten Sie können die Erfassung und Weiterleitung der
              personenbezogenen Daten an Google (insb. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch
              Google verhindern, indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren oder die „Do Not
              Track“ Einstellung Ihres Browsers aktivieren. Sie können darüber hinaus die Erfassung der durch den
              Google-Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
              Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link
              (<a href="http://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank">http://tools.google.com/dlpage/gaoptout?hl=de</a>) verfügbare Browser-Plug-In
              herunterladen und installieren. Die Sicherheits- und Datenschutzgrundsätze von Google finden Sie unter
              <a href="https://policies.google.com/privacy?hl=de"
                target="_blank">https://policies.google.com/privacy?hl=de</a>.
            </li>
          </ul>
        </div>
        <div>
          <h4 class="secondary-heading">HubSpot CRM</h4>
          <ul class="mb-3" style="list-style-type:disc;">
            <li>Wir nutzen das CRM-Tool von Hubspot Inc. 2 Canal Park, Cambridge, MA 02141, United States.</li>
            <li>Bei HubSpot handelt es sich um eine integrierte Software-Lösung mit der verschiedene Online Marketing
              Aktivitäten abgedeckt werden können. Dies umfasst z.B. unser Webseiten Content Management, E-Mail
              Marketing, Social Media Publishing & Reporting, Kontaktmanagement, sowie die Bereitstellung etwaiger
              Landing Pages und von Kontaktformularen.</li>
            <li>Über unseren Anmelde-Service ermöglichen wir unseren Webseitenbesuchern, mehr über unser Unternehmen zu
              erfahren. Zudem können Sie Inhalte herunterladen und Ihre Kontaktinformationen sowie weitere demografische
              Informationen, zur Verfügung stellen. Diese Informationen sowie die Inhalte unserer Website werden auf
              Servern unseres Softwarepartners HubSpot gespeichert und können von uns zur Kontaktaufnahme mit unseren
              Webseitenbesuchern verwendet werden. Zudem nutzen wir die Informationen dazu, um zu ermitteln, welche
              Leistungen unseres Unternehmens für Sie interessant sind.
              Wir nutzen HubSpot auf Basis Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a) DSGVO. HubSpot Forms verwenden
              wir auf Basis unseres berechtigen Interesses gemäß Art. 6 Abs. 1 lit. f) DSGVO, wobei unser berechtigtes
              Interesse in der Bereitstellung etwaiger Kontaktformulare und der vereinfachten Kommunikation mit
              Interessenten liegt. Wir weisen darauf hin, dass bei der Nutzung von HubSpot Forms, kein personenbezogenes
              Tracking wie bei HubSpot selbst, erfolgt.
            </li>
            <li>HubSpot ist ein Software-Unternehmen aus den USA. Um die Datenübermittlung in die USA zu legitimieren,
              stützt sich die HubSpot Inc. auf EU-Standardvertragsklauseln: <a href="https://legal.hubspot.com/dpa"
                target="_blank">https://legal.hubspot.com/dpa</a></li>
            <li>Weiterführende Informationen zum Datenschutz können Sie den Datenschutzbestimmungen von HubSpot
              entnehmen: <a href="https://legal.hubspot.com/privacy-policy" target="_blank"> Datenschutzerklärung
                HubSpot</a>,
              <a href="https://legal.hubspot.com/security" target="_blank"> HubSpot Informationen zur EU-DSGVO</a>,
              Informationen zu den <a
                href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser"
                target="_blank">von HubSpot verwendeten Cookies</a>.
            </li>
          </ul>
        </div>
      </div>
      <!--div 5 end-->
      <!--div 6 start-->
      <div>
        <li class="main-heading">
          <h4> Betroffenenrechte</h4>
        </li>
        <hr>
        <div>
          <p>Sie haben das Recht:</p>
          <ul class="mb-3" style="list-style-type:disc;">
            <li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen.
              Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten,
              die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante
              Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder
              Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns
              erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich
              Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;</li>
            <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns
              gespeicherten personenbezogenen Daten zu verlangen;</li>
            <li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen,
              soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur
              Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</li>
            <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen,
              soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber
              deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen
              die Verarbeitung eingelegt haben;</li>
            <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
              strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen
              Verantwortlichen zu verlangen;</li>
            <li>gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies
              hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht
              mehr fortführen dürfen und
            </li>
            <li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür
              an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres
              Unternehmenssitzes wenden.</li>
          </ul>
        </div>
      </div>
      <!--div 6 end-->
      <!--div 7 start-->

      <div>
        <li class="main-heading">
          <h4>Widerspruchsrecht</h4>
        </li>
        <hr>
        <div>
          <p>Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit.
            f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung
            Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen
            Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein
            generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.</p>
          <p>Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an
            <a href="mailto:datenschutz@sparrks.de"> datenschutz@sparrks.de</a>.
          </p>
        </div>
      </div>
      <!--div 7 end-->
      <!--div 8 start-->
      <div>
        <li class="main-heading">
          <h4>Datensicherheit</h4>
        </li>
        <hr>
        <p>Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung
          mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt
          es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt,
          greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes
          verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise
          Schloss-Symbols in der unteren Statusleiste Ihres Browsers.</p>
        <p>Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten
          gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen
          den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der
          technologischen Entwicklung fortlaufend verbessert.</p>
      </div>
      <!--div 8 end-->
      <!--div 9 start-->
      <div>
        <li class="main-heading">
          <h4>Aktualität und Änderung dieser Datenschutzerklärung</h4>
        </li>
        <hr>
        <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand März 2024.</p>
        <p>Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher
          beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die
          jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter
          <a href="https://www.sparrks.io/datenschutzerklaerung" target="_blank">
            https://www.sparrks.io/datenschutzerklaerung</a> von Ihnen abgerufen und ausgedruckt werden.
      </div>
    </ol>
  </div>
  <!--list div end-->
</div>
