import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgOtpInputModule } from  'ng-otp-input';

import { ExtrapagesRoutingModule } from './extrapages-routing.module';
import { Page404Component } from './page404/page404.component';
import { ApprovalHandlerComponent } from './approval-handler/approval-handler.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [ Page404Component, ApprovalHandlerComponent],
  imports: [
    CommonModule,
    CarouselModule,
    ExtrapagesRoutingModule,
    NgOtpInputModule,
    NgbDropdownModule,
    TranslateModule
  ]
})
export class ExtrapagesModule { }
