import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CoacheeService } from 'src/app/core/services/coachee_services/coachee.service';
import { CoachService } from 'src/app/core/services/coach_services/coach.service';
import { TopbarDetailsService } from 'src/app/core/services/topbar_details.service';
import Swal from 'sweetalert2';
import { PasswordValidator } from 'src/app/shared/validation/password.validator';
import { MESSAGES } from 'src/app/core/constants/messages.constants';
import { CustomFieldConfig } from 'src/app/core/dtos/custom-field-config.dto';
import { CoacheeManagementService } from 'src/app/core/services/coachee_services/coachee-management.service';

declare var $: any;
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

export class TopbarComponent implements OnInit {
  disableBookingBtn: boolean = false;
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  rememberMe: boolean;
  storedCoacheeUserInfo: any = {};
  validationform: FormGroup;
  imgUrl: string | ArrayBuffer;
  submit: boolean;
  coachee_userDetails: any = {
    coachee_id: "",
    coachee_first_name: "",
    coachee_last_name: "",
    coachee_email_id: "",
    profile_image_path: ""
  };

  other_language_pref: any = "";

  coach_userDetails: any = {
    coach_id: "",
    coach_first_name: "",
    coach_last_name: "",
    coach_email_id: "",
    profile_image_path: ""
  };
  adminProfileImageRaw: File;
  buttonLoader: boolean = false;
  @ViewChild('submitForm') public formValue: NgForm;
  @ViewChild('customNav') customNav;
  isCoach: string;
  isCoachee: string;
  fetched_coachee_first_name: any;
  fetched_coachee_last_name: any;
  fetched_email: any;
  fetched_position: any;
  fetched_work_experience: any;
  fetched_language: any;
  fetched_gender: any;
  fetched_phone_number: any;
  fetched_phone_number_country_code: any;
  passwordNotMatched: boolean;
  passwordSimilarToOldPassword: boolean;
  storedCoachInfo: any;
  gcs_pre_path: any;
  topbar_info: string;
  countryList: any;
  loading: boolean;
  topicsUsecasesList: any;
  coachList: any;
  languageList: any;
  usecasesList: any;
  disableUsecaseDropdown: boolean = true;
  today: string;
  time: string;
  language: string;
  fetched_language_id: number;
  minimumDate: Date;
  areYouSure;
  wantToLogout;
  yesLogout;
  cancelText: string;
  coachee_able_to_book: any;
  todayDateTime: string;
  coacheeName: string;
  sidebarLogo: string;
  passwordForm: FormGroup;
  submitted = false;
  bookingForm: FormGroup;
  customFieldsConfig: CustomFieldConfig[];
  browserLang: string;

  get form() {
    return this.validationform.controls;
  }

  constructor(@Inject(DOCUMENT) private document: any, private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private _coacheeService: CoacheeService,
    private _coachService: CoachService,
    private _topBarInfo: TopbarDetailsService,
    private coacheeManagementService: CoacheeManagementService,
  ) { }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
  ];

  openMobileMenu: boolean;

  get f() { return this.passwordForm.controls; }

  ngOnInit() {
    this.getTopbarInfo();
    this.initializeForms();
    this.initializeBookingForm();
    this.subscribeToFormChanges();

    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    if (this._cookiesService.check('lang')) {
      this.browserLang = this._cookiesService.get('lang');
      if (this.browserLang == 'en') {
        this.language = 'english';
        this.areYouSure = 'Are you sure, want to logout?';
        this.wantToLogout = 'Want To Logout!';
        this.yesLogout = 'Yes, logout!';
        this.cancelText = 'Cancel';

      } else {
        this.language = 'german';
        this.areYouSure = 'Möchtest du dich wirklich abmelden?';
        this.wantToLogout = 'Abmelden wollen!';
        this.yesLogout = 'Ja, abmelden!';
        this.cancelText = 'Abbrechen';
      }
    }


    if (localStorage.getItem('rememberMe')) {
      this.rememberMe = true;
      this.isCoach = localStorage.getItem('isCoach');
      this.isCoachee = localStorage.getItem('isCoachee');
    } else {
      this.rememberMe = false;
      this.isCoach = sessionStorage.getItem('isCoach');
      this.isCoachee = sessionStorage.getItem('isCoachee');
    }

    //disable before today
    this.todayDateTime = new Date().toISOString();
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/; //remove seconds and milliseconds.
    this.todayDateTime = regex.exec(this.todayDateTime).toString();

    this.minimumDate = new Date();

    this.rememberMe ? this.storedCoachInfo = localStorage.getItem('storedCoachInfo') : this.storedCoachInfo = sessionStorage.getItem('storedCoachInfo');
    this.storedCoachInfo ? this.storedCoachInfo = JSON.parse(this.storedCoachInfo) : this.storedCoachInfo = {};

    if (this.storedCoachInfo["coach_id"]) {
      this.coach_userDetails.coach_id = this.storedCoachInfo["coach_id"];
      this.coach_userDetails.coach_first_name = this.storedCoachInfo["first_name"];
      this.coach_userDetails.coach_last_name = this.storedCoachInfo["last_name"];
      let coach_image_path = this.storedCoachInfo["profile_image_path"];

      this.gcs_pre_path = this.storedCoachInfo["gcs_pre_path"];
      if (coach_image_path) {
        this.coach_userDetails.profile_image_path = this.gcs_pre_path.concat("/", this.coach_userDetails.profile_image_path);
      }
      this.fetchCoachProfileDetails(this.coach_userDetails.coach_id);

      if (this.language == 'english') {
        this._topBarInfo.putTopbarInfo(`Welcome to Sparrks, ${this.coach_userDetails.coach_first_name}!`);
      } else {
        this._topBarInfo.putTopbarInfo(`Willkommen bei Sparrks, ${this.coach_userDetails.coach_first_name}!`);
      }
    }

    this.rememberMe ? this.storedCoacheeUserInfo = localStorage.getItem('storedCoacheeUserInfo') : this.storedCoacheeUserInfo = sessionStorage.getItem('storedCoacheeUserInfo');
    this.storedCoacheeUserInfo ? this.storedCoacheeUserInfo = JSON.parse(this.storedCoacheeUserInfo) : this.storedCoacheeUserInfo = {};
    if (this.storedCoacheeUserInfo["coachee_id"]) {
      this.coachee_userDetails.coachee_id = this.storedCoacheeUserInfo["coachee_id"];
      this.coachee_userDetails.coachee_first_name = this.storedCoacheeUserInfo["first_name"];
      this.coachee_userDetails.coachee_last_name = this.storedCoacheeUserInfo["last_name"];
      this.coachee_userDetails.profile_image_path_original = this.storedCoacheeUserInfo["profile_image_path"];
      this.gcs_pre_path = this.storedCoacheeUserInfo["gcs_pre_path"];
      this.coachee_userDetails.profile_image_path = this.gcs_pre_path.concat("/", this.coachee_userDetails.profile_image_path_original);
      this.fetchCoacheeProfileDetails(this.coachee_userDetails.coachee_id);

      this._topBarInfo.update_list.subscribe((value) => {
        this.fetchCoacheeActiveJourney(this.coachee_userDetails.coachee_id);
      });
      this.getAllTopicsAndUsecases();
    }
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = './assets/sparrks_js/custom.js';
    document.head.appendChild(script);

    script.onload = function () {
    };
    this.coacheeName = this.coachee_userDetails.coachee_first_name.charAt(0) + this.coachee_userDetails.coachee_last_name.charAt(0);
  }

  initializeForms() {
    this.validationform = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
    });

    this.passwordForm = this.formBuilder.group({
      old_password: new FormControl(null, Validators.required),
      new_password: new FormControl(null, PasswordValidator()),
      confirm_password: new FormControl(null, PasswordValidator())
    });

    this.passwordForm.get('new_password').valueChanges.subscribe(() => {
      this.onPasswordChange();
    });

    this.passwordForm.get('confirm_password').valueChanges.subscribe(() => {
      this.onPasswordChange();
    });
  }

  initializeBookingForm() {
    this.bookingForm = this.formBuilder.group({
      coach_option: ['', Validators.required],
      pref_coach_id: [''],
      topic_id: ['', Validators.required],
      usecase_id: ['', Validators.required],
      objectives: ['', Validators.required],
      first_date_slot: ['', Validators.required],
      second_date_slot: ['', Validators.required],
      third_date_slot: ['', Validators.required],
      field_of_work: ['', Validators.required],
      work_experience: ['', Validators.required],
      language: ['', Validators.required],
      other_language_pref: [''],
      country_code: ['', Validators.required],
      phone_number: ['', Validators.required],
    });
  }

  subscribeToFormChanges() {
    this.bookingForm.get('language').valueChanges.subscribe(value => {
      const otherLanguage = this.bookingForm.get('other_language_pref');
      if (value === '3') {
        otherLanguage.setValidators(Validators.required);
      } else {
        otherLanguage.setValidators(null);

      }
      otherLanguage.updateValueAndValidity();
    });

    this.bookingForm.get('coach_option').valueChanges.subscribe(value => {
      const prefCoach = this.bookingForm.get('pref_coach_id');
      if (value === 'yes') {
        prefCoach.setValidators(Validators.required);
      } else {
        prefCoach.setValidators(null);

      }
      prefCoach.updateValueAndValidity();
    });
  }

  setSidebarLogo() {
    this._coacheeService.setSidebarLogo(this.sidebarLogo);
  }


  //fetch coachee active journies
  fetchCoacheeActiveJourney(coachee_id) {
    let response;
    let coachee_id_details = new FormData();
    coachee_id_details.append('coachee_id', coachee_id);

    this._coacheeService.fetchCoacheeActiveJourney(coachee_id_details).subscribe(
      (data) => {
        response = data;
      },
      error => console.error('error', error),
      () => {
        if (response.success) {
          this.coachee_able_to_book = response["data"]["coachee_able_to_book"];
        } else {
          if (response.error && response.error.error_message) {
            this.toastr.error(response.error.error_message);
          }
        }

      }
    );
  }

  onChangedDate(event, datePickerName, bookSession) {
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();
    const minimumDate = new Date(currentDate.getTime() + 48 * 60 * 60 * 1000);
    if (selectedDate < minimumDate) {
      const lang = this.language === 'english' ? 'english' : 'german';
      this.toastr.error(MESSAGES.error.bookingAllowedInAdvance[lang]);
      event.target.value = null;
      bookSession.value[datePickerName] = null;
      return;
    }

    if (bookSession.form.controls[datePickerName]) {
      bookSession.form.controls[datePickerName].setValue(selectedDate);
    }
  }

  //get topbar info
  getTopbarInfo() {
    this._topBarInfo.topbar_info.subscribe((value) => {
      this.topbar_info = value;
    });
  }


  fetchCoachProfileDetails(coach_id) {
    let response;
    let coach_details = new FormData();
    coach_details.append('coach_id', coach_id);
    this._coachService.fetchCoachProfileDetails(coach_details).subscribe(
      (data) => {
        response = data;
      },
      error => {
        console.error(error);
      },
      () => {
        let data: any = {};
        var coach_profile_path = response["data"]["coach_info"]["profile_image_path"];
        if (coach_profile_path) {
          this.coach_userDetails.profile_image_path = this.gcs_pre_path.concat("/", coach_profile_path);
        }
        this.coach_userDetails.coach_first_name = response["data"]["coach_info"]["first_name"];
        this.coach_userDetails.coach_last_name = response["data"]["coach_info"]["last_name"];
        this._topBarInfo.setCoachDetails({ email_id: response.data.coach_info.email_id });
      });
  }

  fetchCoacheeProfileDetails(coachee_id) {
    let response;
    let coachee_id_details = new FormData();
    coachee_id_details.append('coachee_id', coachee_id);

    this._coacheeService.fetchCoacheeProfileDetails(coachee_id_details).subscribe(
      (data) => {
        response = data;
      },
      error => console.error('error', error),
      () => {
        if (response.success) {
          this.customFieldsConfig = response.data.custom_fields || [];
          const coacheeCustomValues = response.data.coachee_info.custom_fields || [];
          this.sidebarLogo = response.data.sidebar_logo_url;

          let coachee_profile = response["data"]["coachee_info"];
          this.fetched_coachee_first_name = coachee_profile.first_name;
          this.fetched_coachee_last_name = coachee_profile.last_name;
          this.fetched_email = coachee_profile.email_id;

          this.setSidebarLogo();
          const phoneNumberCountryCode = coachee_profile.phone_number_country_code;

          if (!isNaN(phoneNumberCountryCode) && phoneNumberCountryCode !== undefined && phoneNumberCountryCode !== null) {
            this.fetched_phone_number_country_code = parseInt(phoneNumberCountryCode);
          } else {
            this.fetched_phone_number_country_code = null;
          }

          this.coachee_userDetails.coachee_first_name = coachee_profile.first_name;
          this.coachee_userDetails.coachee_last_name = coachee_profile.last_name;

          this.bookingForm.patchValue({
            field_of_work: coachee_profile.field_of_work == "null" ? "" : coachee_profile.field_of_work,
            work_experience: coachee_profile.work_experience,
            language: coachee_profile.language == "english" ? 1 : 2,
            phone_number_country_code: this.fetched_phone_number_country_code,
            phone_number: coachee_profile.phone_number !== ('null' && 'undefined') ? coachee_profile.phone_number : '',
          })

          this.coacheeManagementService.buildFormWithCustomFields(this.bookingForm, this.customFieldsConfig, coacheeCustomValues);
        } else {
          if (response.error && response.error.error_message) {
            this.toastr.error(response.error.error_message);
          }
        }
      }
    );
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
    window.location.reload();
  }

  setDefaultLanguage(text: string, flag: string, lang: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  logout() {
    const me = this;
    $('#coacheeProfileModal').modal('hide');
    this.modalService.dismissAll();
    Swal.fire({
      title: this.areYouSure,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#266cca',
      cancelButtonColor: 'rgba(0, 0, 0, 0.3)',
      cancelButtonText: this.cancelText,
      confirmButtonText: this.yesLogout
    }).then(result => {
      if (result.value) {

        sessionStorage.removeItem('isCoach');
        sessionStorage.removeItem('isCoachee');
        localStorage.removeItem('isCoach');
        localStorage.removeItem('isCoachee');

        if (!this.rememberMe) {
          sessionStorage.removeItem("storedCoachInfo");
          sessionStorage.removeItem("storedCoacheeUserInfo");

          if (localStorage.getItem("storedCoachInfo")) {
            localStorage.removeItem("storedCoachInfo");
          }

          if (localStorage.getItem("storedCoacheeUserInfo")) {
            localStorage.removeItem("storedCoacheeUserInfo");
          }
        } else {
          localStorage.removeItem("storedCoachInfo");
          localStorage.removeItem("storedCoacheeUserInfo");
        }
        if (this.isCoach == 'true') {
          this.router.navigate(['/account/coach-login']);
        } else {
          me._coacheeService.coacheeLogout().subscribe(() => window.location.assign('/account/coachee-login'));
        }
      }
    });
  }

  largeModal(largeDataModal: any) {
    this.modalService.open(largeDataModal, { size: 'md', centered: true });
  }

  changeProfileImage() {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/png, image/jpeg,image/jpg,image/svg';

    input.onchange = (e) => {
      let event = e.target as HTMLInputElement;
      if (event.files.length != 0) {
        var reader = new FileReader();
        reader.readAsDataURL(event.files[0]); // read file as data url
        this.adminProfileImageRaw = event.files[0];
        reader.onload = (event) => { // called once readAsDataURL is completed
          this.coachee_userDetails.profile_image_path = event.target.result;
        }
      }
    }
    input.click();
  }

  onDismissModal() {
    this.formValue.resetForm();
  }

  onSubmitProfileUpdateForm(form) {
    if (form.invalid) {
      return;
    } else {
      this.buttonLoader = true;
      const coachee_first_name = form.value.first_name;
      const coachee_last_name = form.value.last_name;
      const email = form.value.email;
      const field_of_work = form.value.field_of_work;
      const work_experience = form.value.work_experience;
      const language = form.value.language;

      let profile_details = new FormData();

      profile_details.append('coachee_id', this.coachee_userDetails.coachee_id);
      profile_details.append('first_name', coachee_first_name);
      profile_details.append('last_name', coachee_last_name);
      profile_details.append('field_of_work', field_of_work);
      profile_details.append('work_experience', work_experience);
      profile_details.append('language', language);
      profile_details.append('email_id', email);
      profile_details.append('gender', this.fetched_gender);
      profile_details.append('phone_number', this.fetched_phone_number);
      profile_details.append('phone_number_country_code', this.fetched_phone_number_country_code);
      profile_details.append('gender', this.fetched_gender);

      this._coacheeService.coacheeProfileUpdate(profile_details).subscribe(
        (data) => {
          let response;
          response = data;
          if (response.success == true) {
            this.buttonLoader = false;
            $('#coacheeProfileModal').modal('hide');
            const lang = this.language === 'english' ? 'english' : 'german';
            this.toastr.success(MESSAGES.success.profileUpdated[lang]);
          } else {
            if (response.error && response.error.error_message) {
              this.toastr.error(response.error.error_message);
              this.buttonLoader = false;
            }
          }
        },
        (error) => {
          console.error('error', error);
          this.buttonLoader = false;
          const lang = this.language === 'english' ? 'english' : 'german';
          this.toastr.error(MESSAGES.error.somethingWentWrong[lang]);
        },

      );
    }
  }

  onPasswordChange() {
    const new_password = this.passwordForm.get('new_password').value;
    const confirm_password = this.passwordForm.get('confirm_password').value;
    if (this.submitted && new_password != confirm_password) {
      this.passwordNotMatched = true;
    } else {
      this.passwordNotMatched = false;
    }
    this.passwordSimilarToOldPassword = false;
    this.buttonLoader = false;
    return;
  }

  onPasswordUpdateForm(form) {
    this.submitted = true;
    if (form.invalid) {
      this.buttonLoader = false;
      return;
    } else {
      if (form.value.new_password != form.value.confirm_password) {
        this.passwordNotMatched = true;
        this.passwordSimilarToOldPassword = false;
        this.buttonLoader = false;
        return;
      }
      if (form.value.new_password == form.value.old_password) {
        this.passwordSimilarToOldPassword = true;
        this.passwordNotMatched = false;
        this.buttonLoader = false;
        return;
      }
      const old_password = form.value.old_password;
      const new_password = form.value.new_password;
      let profile_details = new FormData();

      profile_details.append('coachee_id', this.coachee_userDetails.coachee_id);
      profile_details.append('old_password', old_password);
      profile_details.append('new_password', new_password);
      this.buttonLoader = true;

      this._coacheeService.coacheePasswordUpdate(profile_details).subscribe(
        (data) => {
          let response;
          response = data;
          if (response.success == true) {
            this.buttonLoader = false;
            const lang = this.language === 'english' ? 'english' : 'german';
            this.toastr.success(MESSAGES.success.profilePasswordUpdated[lang]);

            form.reset();
            this.submitted = false;
            $('#coacheeProfileModal').modal('hide');
          } else {
            if (response.error && response.error.error_message) {
              this.toastr.error(response.error.error_message);
              this.buttonLoader = false;
            }
          }
        },
        (error) => {
          console.error('error', error);
          this.buttonLoader = false;
          const lang = this.language === 'english' ? 'english' : 'german';
          this.toastr.error(MESSAGES.error.somethingWentWrong[lang]);
        },

      );
    }
  }

  goToCoachProfilePage() {
    this.router.navigate(['coach-pages/coach-profile']);
  }

  getCoacheeProfileDetails() {
    this.onDismissModal();
    this.fetchCoacheeProfileDetails(this.coachee_userDetails.coachee_id);
  }

  getAllTopicsAndUsecases() {
    let language;
    if (this._cookiesService.check('lang')) {
      this.browserLang = this._cookiesService.get('lang');
      if (this.browserLang == 'en') {
        language = 'english';
      } else {
        language = 'german';
      }
    }

    let response;
    let coachee_details = new FormData();
    coachee_details.append('coachee_id', this.coachee_userDetails.coachee_id);
    coachee_details.append('language', language);

    this._coacheeService.getUsecasesAndTopics(coachee_details).subscribe(
      (data) => {
        response = data;
      },
      error => {
        console.error(error);
        this.loading = false;
      },
      () => {
        if (response.success) {
          this.topicsUsecasesList = response["data"]["coachee_usecases_info"];
          this.coachList = response["data"]["coachList"];
          this.languageList = response["data"]["languageList"];
          this.loading = false;
          this.getCountryList();
        } else {
          if (response.error && response.error.error_message) {
            this.toastr.error(response.error.error_message);
            this.loading = false;
          }
        }

      }
    );
  }

  onChangeTopic(event) {
    if (event.target.value) {
      let topic_id = event.target.value;
      for (let i = 0; i < this.topicsUsecasesList.length; i++) {
        if (this.topicsUsecasesList[i]["topic_id"] && this.topicsUsecasesList[i]["topic_id"] == topic_id) {
          this.usecasesList = this.topicsUsecasesList[i]["usecases"];
          this.disableUsecaseDropdown = false;
        }
      }
    } else {
      this.disableUsecaseDropdown = true;
    }
  }

  onChangeUsecase(event) { }

  onChangeLanguage(event) { }

  onSubmitForm(form) {
    if (form.invalid) {
      const invalid = [];
      const controls = form.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      return;
    } else {
      this.buttonLoader = true;
      var pref_coach = form.value.pref_coach_id;
      if (pref_coach == undefined || pref_coach == 'undefined') {
        pref_coach = "";
      }
      const topic_id = form.value.topic_id;
      const usecase_id = form.value.usecase_id;
      const objectives = form.value.objectives;
      const field_of_work = form.value.field_of_work;
      const work_experience = form.value.work_experience;
      const language = form.value.language;
      const country_code = form.value.country_code;

      const first_time_slot = form.value.first_date_slot;
      const second_time_slot = form.value.second_date_slot;
      const third_time_slot = form.value.third_date_slot;

      let first_time_slot_utc = new Date(first_time_slot).toUTCString();
      let second_time_slot_utc = new Date(second_time_slot).toUTCString();
      let third_time_slot_utc = new Date(third_time_slot).toUTCString();
      const phone_number = form.value.phone_number;

      let session_booking_details = new FormData();

      session_booking_details.append('coachee_id', this.coachee_userDetails.coachee_id);
      session_booking_details.append('prefferred_coach', pref_coach);
      session_booking_details.append('topic_id', topic_id);
      session_booking_details.append('usecase_id', usecase_id);
      session_booking_details.append('objectives', objectives);
      session_booking_details.append('field_of_work', field_of_work);
      session_booking_details.append('work_experience', work_experience);
      session_booking_details.append('language_preference_id', language);
      session_booking_details.append('coachee_preference_date_time_1', first_time_slot_utc);
      session_booking_details.append('coachee_preference_date_time_2', second_time_slot_utc);
      session_booking_details.append('coachee_preference_date_time_3', third_time_slot_utc);
      session_booking_details.append('phone_number', phone_number);
      session_booking_details.append('phone_number_country_code', country_code);
      session_booking_details.append('other_language', this.other_language_pref);
      // Append custom fields dynamically from the profile form
      if (this.customFieldsConfig) {
        this.customFieldsConfig.forEach(field => {
          const value = this.bookingForm.get(field.name).value;
          session_booking_details.append(field.name, value ?? "");  // Use an empty string if the value is null or undefined
        });
      }

      this._coacheeService.bookSession(session_booking_details).subscribe(
        (data) => {
          let response;
          response = data;
          if (response.success == true) {
            const lang = this.language === 'english' ? 'english' : 'german';
            this.toastr.success(MESSAGES.success.sessionBooked[lang]);
            this.buttonLoader = false;
            $('#session_booking_topbar').modal('hide');
            $('#thank-you-page').modal('show');
            this.coachee_able_to_book = false;
            this.disableUsecaseDropdown = false;
            form.resetForm();
          } else {
            if (response.error && response.error.error_message) {
              this.toastr.error(response.error.error_message);
              this.buttonLoader = false;
            }
          }
        },
        (error) => {
          console.error('error', error);
          this.buttonLoader = false;
          const lang = this.language === 'english' ? 'english' : 'german';
          this.toastr.error(MESSAGES.error.somethingWentWrong[lang]);
        },
      );
    }
  }

  getCountryList() {
    let response;
    this._coacheeService.getCountryList().subscribe(
      (data) => {
        response = data;
      },
      error => {
        console.error(error);
        this.loading = false;
      },
      () => {
        if (response.success) {
          this.countryList = response["data"]["country_list"];
        } else {
          if (response.error && response.error.error_message) {
            this.toastr.error(response.error.error_message);
          }
        }
      }
    );
  }

  resetSessionBookingForm(form) {
    form.resetForm();
    this.getAllTopicsAndUsecases();
    this.getCountryList();
  }

  openSessionBookingModal() {
    this.fetchCoacheeProfileDetails(this.coachee_userDetails.coachee_id);
  }
}
