
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const specialChars = "!\"§$%&/\\|/{}[]()=?ßöüäÖÄÜ`´«@#'+*±,;.:µ<>-_".split('')

export function PasswordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value: string = control.value;
    if (!isValidPassword(value)) {
      return { invalidPassword: true };
    }

    return null;
  };
}

export function isValidPassword(v: string): boolean {
  const hasLetter = /[a-zA-Z]/.test(v);
  const hasDigit = /[0-9]/.test(v);
  return !(!hasLetter || !hasDigit || !hasSpecialChar(v) || v.length < 8);
}

function hasSpecialChar(v: string): boolean {
  for (let special of specialChars) {
    if (v.includes(special)) {
      return true
    }
  }
  return false
}
