import { Injectable } from '@angular/core';
import { Router, CanActivate} from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
    ) { }

    canActivate() {
        let rememberMe: boolean;
        let storedCoacheeUserInfo: any;

        if(localStorage.getItem('rememberMe')) {
            rememberMe = true;
        } else {
            rememberMe = false;
        }
          

        rememberMe ? storedCoacheeUserInfo = localStorage.getItem('storedCoacheeUserInfo') : 
        storedCoacheeUserInfo = sessionStorage.getItem('storedCoacheeUserInfo');
        
        storedCoacheeUserInfo = localStorage.getItem('storedCoacheeUserInfo');
        storedCoacheeUserInfo ? storedCoacheeUserInfo = JSON.parse(storedCoacheeUserInfo) : storedCoacheeUserInfo = {};

        if(storedCoacheeUserInfo.coachee_id) {
            sessionStorage.setItem('storedCoacheeUserInfo',JSON.stringify(storedCoacheeUserInfo));
           if(rememberMe){
               sessionStorage.setItem('rememberMe','true');
           }else {
               sessionStorage.setItem('rememberMe','false');
           }

           sessionStorage.setItem('isCoach',localStorage.getItem('isCoach'));
           sessionStorage.setItem('isCoachee',localStorage.getItem('isCoachee'));
            return true;
        }
    
        // not logged in so redirect to login page with the return url
        console.warn('in authguard if stored coachee info not present');
        this.router.navigate(['account/coachee-email']);
        
        return false;
    }
}
