import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({ providedIn: 'root' })
export class TopbarDetailsService {
    public topbar_info = new BehaviorSubject('');
    public update_list = new BehaviorSubject(false);
    private coachDetailsSubject = new BehaviorSubject<any>(null);
    coachDetails$ = this.coachDetailsSubject.asObservable();

    constructor() { }

  putTopbarInfo(topbar_info){
    // alert('changed')
    this.topbar_info.next(topbar_info);
  }

  getTopbarInfo(){
    return this.topbar_info;
  }

  updateListByObservable(flag){
    this.update_list.next(flag);
  }

  setCoachDetails(details: any) {
    this.coachDetailsSubject.next(details);
  }
}
