import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";


@Injectable({ providedIn: 'root' })
export class CoachAuthGuard implements CanActivate {
    constructor(
        private router: Router,
    ) { }

    canActivate() {
        let rememberMe: boolean;
        let storedCoachInfo: any;

        if (localStorage.getItem('rememberMe')) {
            rememberMe = true;
        } else {
            rememberMe = false;
        }

        rememberMe ? storedCoachInfo = localStorage.getItem('storedCoachInfo') :
            storedCoachInfo = sessionStorage.getItem('storedCoachInfo');

        storedCoachInfo = localStorage.getItem('storedCoachInfo');
        storedCoachInfo ? storedCoachInfo = JSON.parse(storedCoachInfo) : storedCoachInfo = {};

        if (storedCoachInfo.coach_id) {
            sessionStorage.setItem('storedCoachInfo', JSON.stringify(storedCoachInfo));
            if (rememberMe) {
                sessionStorage.setItem('rememberMe', 'true');
            } else {
                sessionStorage.setItem('rememberMe', 'false');
            }
            sessionStorage.setItem('isCoach', localStorage.getItem('isCoach'));
            sessionStorage.setItem('isCoachee', localStorage.getItem('isCoachee'));

            return true;
        }
        this.router.navigate(['account/coach-login']);

        return false;
    }
}
