<div>
  <div class="consent-header">
    <h4>
      Einwilligung bzw. datenschutzrechtliche Einverständniserklärung zur
      Verwendung personenbezogener Daten durch den Coach
    </h4>
  </div>

  <!--list div start-->
  <div class="consent-container">
    <ol class="terms-condition-block mb-3">
      <!--div 1 start-->
      <div>
        <li class="main-heading">
          <h4>Grund für die Datenerhebung und Art der Erhobenen Daten</h4>
        </li>
        <hr />
        <div>
          <p>
            Ihr Arbeitgeber ermöglicht es Ihnen, auf seine Kosten an einem durch
            die SPARRKS Service GmbH, Schönhauser Allee 167c, 10435 Berlin
            vermittelten Coaching teilzunehmen. Hierzu hat Ihr Arbeitgeber mit
            der SPARRKS Service GmbH einen Vertrag über die Vermittlung von
            Coachings und Coaches geschlossen. Der Coach führt als freier
            Mitarbeiter derartige Coachings auf Basis eines Dienstvertrages im
            Auftrag der SPARRKS Service GmbH durch.
          </p>
          <p>
            Die Anmeldung zu einem für Sie interessanten Coaching findet über
            das Buchungsportal der SPARRKS Service GmbH statt.
          </p>
          <p>
            Wenn Sie sich für ein Coaching entscheiden, dass durch einen Coach
            angeboten wird oder Ihnen ein geeigneter Coach vorgeschlagen wird,
            übermittelt die SPARRKS Service GmbH die folgenden persönlichen
            Daten von Ihnen, damit der Coach eine Entscheidungsgrundlage hat, ob
            sie bzw. er das Coaching durchführen kann:
          </p>
          <ul class="mb-2" style="list-style-type: disc">
            <li>Vorname, Nachname,</li>
            <li>E-Mail Adresse,</li>
            <li>Sprache,</li>
            <li>Arbeitgeber,</li>
            <li>Position,</li>
            <li>Berufserfahrung,</li>
            <li>Führungserfahrung,</li>
            <li>Anliegen.</li>
          </ul>
          <p>
            Lehnt der Coach die Buchungsanfrage ab, werden Ihre
            personenbezogenen Daten vom Coach unverzüglich gelöscht.
          </p>
          <p>
            Nimmt der Coach die Buchung an, werden die genannten Daten für Dauer
            des Coachings zum Zwecke der Durchführung des Coachings gespeichert
            und verarbeitet. Eine anderweitige Verarbeitung Ihrer Daten als zum
            Zwecke des Coachings findet nicht statt. Nach Beendigung des
            Coachings werden alle persönlichen Daten, die sich auf Sie als
            Coachee beziehen, vom Coach gelöscht. Etwas anderes kann im
            Einzelfall vereinbart werden, wenn Sie ein weiteres Coaching
            wünschen und ausdrücklich möchten, dass die Notizen zu diesem Zweck
            aufbewahrt werden. In diesem Fall ist eine gesonderte
            Einwilligungserklärung durch Sie gegenüber dem Coach erforderlich.
            Das Coaching ist abgeschlossen, wenn Sie sich mit Ihrem Coach über
            eine Beendigung des Coachings verständigt haben.
          </p>
          <p>
            Das Coaching selbst wird via Microsoft Teams durchgeführt und wird
            nicht aufgezeichnet, so dass hier keine Datenspeicherung und
            Verarbeitung stattfindet. Weder die SPARRKS Service GmbH noch Ihr
            Arbeitgeber haben die Möglichkeit, hierauf irgendwie zuzugreifen.
          </p>
          <p>
            Im Verlauf des Coachings wird sich der Coach über Ihre Angaben aber
            Notizen machen. Hierbei kann es sich im Einzelfall auch um besonders
            persönliche Angaben und auch um Gesundheitsdaten handeln. Wenn Sie
            dem Coach derartige Informationen anvertrauen, werden diese entweder
            auf Papier notiert, wobei dieses Papier das Büro nicht verlässt und
            auch nicht gescannt, abfotografiert o.ä. wird oder der Coach notiert
            Ihre Angaben offline in einer Datei auf seinem Computer. Nach
            Beendigung des Coachings werden diese Notizen unverzüglich gelöscht
            oder vernichtet. Etwas anderes kann im Einzelfall vereinbart werden,
            wenn Sie ein weiteres Coaching wünschen und ausdrücklich möchten,
            dass die Notizen zu diesem Zweck aufbewahrt werden. In diesem Fall
            ist eine gesonderte Einwilligungserklärung durch Sie gegenüber dem
            Coach erforderlich. Das Coaching ist abgeschlossen, wenn Sie sich
            mit Ihrem Coach über eine Beendigung des Coachings verständigt
            haben.
          </p>
        </div>
      </div>

      <!--div 1 end-->
      <!--div 2 start-->
      <div>
        <li class="main-heading">
          <h4>Verarbeiter und andere Empfänger von personenbezogenen Daten</h4>
        </li>
        <hr />
        <p>
          Die Datenverarbeitung wird seitens der SPARRKS Service GmbH auf den
          folgenden Servern gehostet:
        </p>
        <ul class="mb-3" style="list-style-type: disc">
          <li>Rechenzentrum von Google, Frankfurt</li>
        </ul>
      </div>
      <!--div 2 end-->
      <!--div 3 start-->
      <div>
        <li class="main-heading">
          <h4>
            Rechte des Betroffenen: Auskunft, Berichtigung, Löschung und
            Sperrung, Widerruf
          </h4>
        </li>
        <hr />
        <div>
          <p>
            Als von der Datenverarbeitung Betroffener haben sie folgende Rechte:
          </p>
          <ul class="mb-3" style="list-style-type: disc">
            <li>
              Auskunft über Ihre bei uns gespeicherten Daten und deren
              Verarbeitung (Art. 15 DSGVO)
            </li>
            <li>
              Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO)
            </li>
            <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO)</li>
            <li>
              Einschränkung der Datenverarbeitung, sofern wir Ihre Daten
              aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18
              DSGVO)
            </li>
            <li>
              Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
              eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben
              (Art. 20 DSGVO)
            </li>
            <li>
              Sie haben zudem das Recht, die von Ihnen erteilte Einwilligung
              bzw. die von Ihnen erteilten Einwilligungen jederzeit ohne Angabe
              einer Begründung für die Zukunft zu widerrufen oder abzuändern
              (Art. 21 DSGVO).
            </li>
            <li>
              Sie können sich jederzeit mit einer Beschwerde an eine
              Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde
              des Bundeslands Ihres Wohnsitzes oder an die für uns als
              verantwortliche Stelle zuständige Behörde.
            </li>
          </ul>
        </div>
      </div>
      <!--div 3 end-->
      <!--div 4 start-->
      <div>
        <li class="main-heading">
          <h4>
            Verantwortlicher im Sinne der DSGVO und Ansprechpartner für die
            Datenverarbeitung
          </h4>
        </li>
        <hr />
        <div>
          <p>
            Verantwortlicher durch die Datenverarbeitung ist der von Ihnen
            ausgewählte Coach.
          </p>
        </div>
      </div>
      <!--div 4 end-->
      <!--div 5 start-->
      <div>
        <li class="main-heading">
          <h4>Folgen der Nicht-Einwilligung</h4>
        </li>
        <hr />
        <p>
          Sie haben das Recht, dieser Datenverarbeitung durch den Coach nicht
          zuzustimmen. Da die Durchführung des Coachings jedoch auf die Erhebung
          und Verarbeitung genannter Daten angewiesen sind, würde eine eine
          nicht erteilte Einwilligung eine Inanspruchnahme des Coachings
          ausschließen.
        </p>
      </div>
      <!--div 5 end-->
      <!--div 6 start-->
      <div class="pb-5 pt-3">
        <div class="main-heading">
          <h4 class="text-center">Einwilligung</h4>
        </div>
        <p>
          Ich erkläre meine Einwilligung, dass die vorgenannten
          personenbezogenen Daten über mich zum Zwecke der Durchführung des
          Coachings von dem Coach gespeichert werden dürfen. Ich erkläre, dass
          ich diese Einwilligung freiwillig abgebe. Mir ist bekannt, dass ich
          meine Einwilligung mit Wirkung für die Zukunft jederzeit widerrufen
          kann. Im Falle des Widerrufs finden die einschlägigen
          Datenschutzbestimmungen Anwendung.
        </p>
        <p>
          Hiermit versichere ich, der Erhebung und der Verarbeitung meiner Daten
          durch die bzw. den Coach wie oben angekreuzt zuzustimmen und über die
          Rechte belehrt worden zu sein.
        </p>
      </div>
    </ol>
  </div>
  <!--list div end-->
</div>
